import { array, object, str } from "sydneyeval-shared";

export const Incident = object({
  product: str,
  title: str,
  description: str,
  creator: str,
  createdAt: str,
});

export const IncidentList = array(Incident);

export type Incident = ReturnType<typeof Incident>;

export type IncidentList = ReturnType<typeof IncidentList>;
