import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import React, { useEffect, useRef } from "react";

import { observer } from "mobx-react-lite";
import { uploadFolderAction } from "../../actions/uploadFolderAction";
import { getInputFile, getValue } from "../../selectors/getJobPropV2";
import { FilterButton } from "../Other/FilterButton";

const useStyles = makeStyles({
  noticeInfo: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    fontStyle: "italic",
    lineHeight: "22px",
    color: "red",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    lineHeight: "22px",
  },
  typeSelect: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  errorMsg: {
    color: "red",
  },
  successMsg: {
    color: "green",
  },
});

interface IUploadingFolderDialogProps {
  folderName: string;
  isOpen: boolean;
  onStart?: (folderName: string) => void;
  onSuccess?: (currentFolderName: string, folderName: string) => void;
  onFailure?: (error: Error) => void;
  onCancel?: () => void;
}

export const UploadingFolderDialog = observer(
  (props: IUploadingFolderDialogProps) => {
    const inputFolder = useRef<HTMLInputElement | null>(null);
    const { folderName, isOpen } = props;
    const styles = useStyles();

    const [currentFolderName, setCurrentFolderName] =
      React.useState<string>("");
    const [selectedFolder, setSelectedFolder] = React.useState<
      File[] | undefined
    >(undefined);

    const [isXlsxFiles, setIsXlsxFiles] = React.useState<boolean>(false);

    const [validationMessage, setValidationMessage] = React.useState<
      string | undefined
    >(undefined);

    useEffect(() => {
      setCurrentFolderName("");
      setIsXlsxFiles(false);
      setSelectedFolder(undefined);
      setValidationMessage(undefined);
    }, [isOpen]);

    const checkOPGFolderFormat = (files: File[], isXlsxFiles: boolean) => {
      const fileNames = files.map((file) => file.name);
      const options = getValue("configs_in_json.options") ?? [];
      const experiments =
        getValue("configs_in_json.conversations.exp_configs") ?? [];

      const inputFile = getInputFile.get() ?? "";
      const xlsxFiles = experiments.map((exp) => `${exp?.exp_name}.xlsx`);
      const tsvFiles = experiments.map(
        (exp) => `${exp?.exp_name}_conversations.tsv`,
      );

      let targetFiles = xlsxFiles;

      if (!isXlsxFiles) {
        if (options.includes("skip_scraping")) {
          targetFiles = [...tsvFiles, inputFile];
        } else {
          targetFiles = tsvFiles;
        }
      }

      const missingFiles = targetFiles.filter(
        (file: string) => !fileNames.includes(file),
      );
      if (missingFiles.length > 0) {
        setValidationMessage(`Missing files: ${missingFiles.join(", ")}`);
        return;
      }
      setValidationMessage(undefined);
      return;
    };

    const validateFolder = (folder: File[]) => {
      if (folder !== undefined) {
        checkOPGFolderFormat(folder, isXlsxFiles);
      }
    };

    const switchType = (isXlsx: boolean) => {
      setIsXlsxFiles(isXlsx);
      if (selectedFolder !== undefined) {
        checkOPGFolderFormat(selectedFolder, isXlsx);
      }
    };

    const handleFileDialogConfirm = () => {
      if (selectedFolder !== undefined) {
        props.onStart?.(folderName);

        uploadFolderAction(
          "OPG",
          folderName,
          selectedFolder,
          currentFolderName,
          () => {
            props.onSuccess?.(currentFolderName, folderName);
          },
          props.onFailure,
        );
      }
    };

    return (
      <Dialog
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === false) {
            props.onCancel?.();
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{"Upload input Folder"}</DialogTitle>
            <DialogContent>
              <Field>
                <div className={styles.block}>
                  <div className={styles.noticeInfo}>
                    Please upload the eyes-on data of CoPilot Responses. The
                    input data and evaluation results will be public for all
                    users in SEVAL.
                  </div>
                </div>
                <div className={styles.block}>
                  <div className={styles.blockTitle}>
                    First, select your folder type:
                  </div>
                  <div className={styles.typeSelect}>
                    <FilterButton
                      value={"xlsx"}
                      selectedTab={isXlsxFiles ? "xlsx" : "tsv"}
                      text="Xlsx files"
                      activeIcon={undefined}
                      inactionIcon={undefined}
                      clickTab={() => {
                        switchType(true);
                      }}
                    />
                    <FilterButton
                      value={"tsv"}
                      selectedTab={isXlsxFiles ? "xlsx" : "tsv"}
                      text="TSV files"
                      activeIcon={undefined}
                      inactionIcon={undefined}
                      clickTab={() => {
                        switchType(false);
                      }}
                    />
                  </div>
                  <div className={styles.blockTitle}>
                    Then, you can upload your input folder here.
                  </div>

                  {validationMessage && (
                    <div className={styles.errorMsg}>{validationMessage}</div>
                  )}

                  {selectedFolder && validationMessage === undefined && (
                    <div className={styles.successMsg}>
                      <Body1>
                        {`This folder `}
                        <strong>{`${currentFolderName}`}</strong>
                        {` will be stored with name `}
                        <strong>{`${folderName}`}</strong>
                      </Body1>
                    </div>
                  )}
                  <div>
                    <input
                      type="file"
                      ref={(node) => {
                        inputFolder.current = node;

                        if (node) {
                          [
                            "webkitdirectory",
                            "directory",
                            "mozdirectory",
                          ].forEach((attr) => {
                            node.setAttribute(attr, "");
                          });
                        }
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        if (event.target?.files) {
                          const fileList = new Array<File>();
                          Array.from(event.target?.files).forEach(
                            (file, index) => {
                              if (file.name !== ".DS_Store") {
                                fileList.push(file);
                              }
                              if (index === 0) {
                                setCurrentFolderName(
                                  file.webkitRelativePath.split("/")[0],
                                );
                              }
                            },
                          );
                          setSelectedFolder(fileList);

                          validateFolder(fileList);
                        }
                      }}
                      style={{ display: "none" }}
                    />
                    <Button
                      appearance="primary"
                      onClick={() => {
                        inputFolder.current?.click();
                      }}
                    >
                      Select Folder
                    </Button>
                  </div>
                </div>
              </Field>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleFileDialogConfirm}
                disabled={
                  selectedFolder === undefined ||
                  validationMessage !== undefined
                }
                appearance="primary"
              >
                Confirm
              </Button>
              <Button onClick={props.onCancel} appearance="secondary">
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
