import { Button } from "@fluentui/react-components";
import { Delete16Regular, Edit16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { fetchUserInfo } from "../../../../actions/substrateActions";
import { substrateStore } from "../../../../store/substrateStore";
import {
  onDeleteProductClick,
  onEditProductClick,
} from "../../actions/productSettingsActions";
import { productSettingsStore } from "../../store/productSettingsStore";
import { GeneralTable } from "../JobList/GeneralTable";
import { useStyle } from "./sharedStyles";

const CreatorNameCell = observer((props: { id: string }) => {
  fetchUserInfo(props.id);
  const userCache = substrateStore.userCache[props.id];

  if (userCache === undefined || userCache.status === "loading") {
    return <div>Loading...</div>;
  }

  return <div>{userCache.data.DisplayName}</div>;
});

export const ProductListView = observer(() => {
  const styles = useStyle();
  return (
    <GeneralTable
      loadMore={() => {
        return;
      }}
      hasMore={false}
      rows={productSettingsStore.products ?? []}
      columns={[
        {
          title: "Product Name",
          render: (product) => {
            return <div>{product.displayName}</div>;
          },
          weight: 6,
          minWidth: "0px",
        },
        {
          title: "Created By",
          render: (product) => {
            return <CreatorNameCell id={product.creatorId} />;
          },
          weight: 2,
          minWidth: "0px",
        },
        {
          title: "Release Date",
          render: (product) => {
            return <div>{product.publishedTime}</div>;
          },
          weight: 2,
          minWidth: "0px",
        },
        {
          title: "Status",
          render: (product) => {
            return <div>{product.status}</div>;
          },
          weight: 1,
          minWidth: "0px",
        },
        {
          title: "Actions",
          render: (product) => {
            return (
              <div className={styles.pipelineButtonsRow}>
                <Button
                  aria-label="Edit Product"
                  appearance="transparent"
                  size="small"
                  onClick={onEditProductClick.bind(null, product)}
                  icon={<Edit16Regular />}
                />
                <Button
                  aria-label="Delete Product"
                  appearance="transparent"
                  size="small"
                  onClick={onDeleteProductClick.bind(null, product, false)}
                  icon={<Delete16Regular />}
                />
              </div>
            );
          },
          weight: 1,
          minWidth: "0px",
        },
      ]}
    />
  );
});
