import { countBy } from "lodash";
import { computed } from "mobx";
import { isValidSettingsThrowError } from "sydneyeval-shared";
import { tryFormat } from "../helpers/productHelper";
import { jobStore } from "../store/jobStore";
import { productSettingsStore } from "../store/productSettingsStore";

export const getProductNameError = computed(() => {
  const products = productSettingsStore.products;
  const scenario = productSettingsStore.scenario;

  switch (scenario.scenarioType) {
    case "Creating":
      if (scenario.currentProductName.trim() === "") {
        return "Product Name is required";
      }
      if (
        products.some(
          (product) => product.displayName === scenario.currentProductName,
        )
      ) {
        return "Product Name already exists";
      }
      break;

    case "EditingProduct":
      if (scenario.currentProductName.trim() === "") {
        return "Product Name is required";
      }
      if (
        products.some(
          (product) =>
            product.displayName === scenario.currentProductName &&
            product.id !== scenario.selectedProduct.id,
        )
      ) {
        return "Product Name already exists";
      }
      break;

    default:
      break;
  }

  return undefined;
});

export const getCurrentPipelinesError = computed(() => {
  const scenario = productSettingsStore.scenario;

  if (
    scenario.scenarioType !== "Creating" &&
    scenario.scenarioType !== "EditingProduct"
  ) {
    return undefined;
  }

  if (scenario.currentPipelines.length === 0) {
    return "At least one pipeline is required";
  }

  // Display name can not be same in a product
  // Find display names that are not unique
  const displayNames = scenario.currentPipelines.map(
    (pipeline) => pipeline.displayName,
  );
  const displayNamesCount = countBy(displayNames);
  const duplicateDisplayNames = Object.keys(displayNamesCount).filter(
    (key) => displayNamesCount[key] > 1,
  );

  if (duplicateDisplayNames.length > 0) {
    return `Pipeline names must be unique. Duplicates: ${duplicateDisplayNames.join(
      ", ",
    )}`;
  }

  return undefined;
});

export const getPipelineNameError = computed(() => {
  const scenario = productSettingsStore.scenario;

  if (
    scenario.scenarioType !== "EditingProduct" ||
    scenario.currentPipeline === undefined
  ) {
    return undefined;
  }

  const currentPipeline = scenario.currentPipeline;

  if (currentPipeline.displayName.trim() === "") {
    return "Pipeline Name is required";
  }

  if (
    scenario.currentPipelines.find(
      (pipeline) =>
        pipeline.displayName === currentPipeline.displayName &&
        pipeline.id !== currentPipeline.id,
    )
  ) {
    return "Pipeline Name already exists";
  }

  return undefined;
});

export const getPipelineSettingsError = computed(() => {
  const scenario = productSettingsStore.scenario;

  if (
    scenario.scenarioType !== "EditingProduct" ||
    scenario.currentPipeline === undefined
  ) {
    return undefined;
  }

  const currentPipeline = scenario.currentPipeline;

  if (currentPipeline.settingsOverride === undefined) {
    return undefined;
  }

  if (currentPipeline.settingsOverride.trim() === "") {
    return "JSON Configuration is required";
  }

  try {
    JSON.parse(currentPipeline.settingsOverride);

    const targetSchema = productSettingsStore.templates.find(
      (_) => _.ExperimentName === currentPipeline.pipelineExpName,
    )?.SettingsSchema;

    isValidSettingsThrowError(
      scenario.currentPipeline.settingsOverride,
      targetSchema,
    );
  } catch (e) {
    if (e instanceof Error) {
      return e.message;
    }
    return JSON.stringify(e);
  }

  return undefined;
});

export const getCurrentPublishedProducts = computed(() => {
  return productSettingsStore.products.filter(
    (product) => product.status === "published",
  );
});

export const getCurrentSelectedProduct = computed(() => {
  const selectedProduct = getCurrentPublishedProducts
    .get()
    .find(
      (product) =>
        product.id === jobStore.productFilter && product.status === "published",
    );

  return selectedProduct;
});

export const getCurrentDefaultSettings = computed(() => {
  const scenario = productSettingsStore.scenario;

  if (
    scenario.scenarioType !== "EditingProduct" ||
    scenario.currentPipeline === undefined
  ) {
    return "{}";
  }

  const currentPipeline = scenario.currentPipeline;
  const targetTemplate = productSettingsStore.templates.find(
    (_) => _.ExperimentName === currentPipeline.pipelineExpName,
  );

  return tryFormat(targetTemplate?.Settings ?? "{}");
});
