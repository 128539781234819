"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientFeatureFlagConfig = exports.ClientFeatureFlagSets = exports.ClientFeatureFlagScopes = exports.ClientFeatureFlagDefaults = exports.ClientFeatureFlags = void 0;
const typeHelper_1 = require("../tools/typeHelper");
// Please refer the following wiki page for portal client feature rollout
// https://dev.azure.com/msasg/Cortana/_wiki/wikis/M365%20Chat.wiki/275957/Release-Features-for-SEVAL-Portal-Client
exports.ClientFeatureFlags = [
    ["human-correlation-study", false, ["LOCALHOST", "STAGING"]],
    ["m365chat-license-verifier", false],
    ["use-dev-immunity", true],
    ["perf-link-v2", true],
    ["schedule-job-management", false],
    ["product-registration", false],
    ["eval-time-override", false],
    ["show-upgrade-banner", false],
    ["bypass-jobprevalidation", false],
];
exports.ClientFeatureFlagDefaults = exports.ClientFeatureFlags.reduce((acc, cur) => {
    acc[cur[0]] = cur[1];
    return acc;
}, {});
exports.ClientFeatureFlagScopes = exports.ClientFeatureFlags.reduce((acc, cur) => {
    if (cur.length === 3) {
        acc[cur[0]] = cur[2];
    }
    return acc;
}, {});
exports.ClientFeatureFlagSets = (0, typeHelper_1.object)(exports.ClientFeatureFlags.reduce((acc, cur) => {
    acc[cur[0]] = (0, typeHelper_1.optional)(typeHelper_1.bool);
    return acc;
}, {}));
exports.ClientFeatureFlagConfig = (0, typeHelper_1.object)({
    portal: exports.ClientFeatureFlagSets,
});
