import { fileUrlPrefix_bingQuerySet_new } from "../../../constants/constants";
import { getBingUserSets } from "../../../helpers/apiHelper";
import { store } from "../../../store/store";

export const verifyBingQuerySetFile = (file: File) => {
  const userId = store.account?.localAccountId;
  if (!userId) {
    throw new Error(`Please login first.`);
  }

  const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
  const fileNameWithoutTsv = file.name.replace(".tsv", "");
  if (!regexStr.test(fileNameWithoutTsv)) {
    throw new Error(
      `Invalid file name: ${file.name}, file name should start with a letter or an underscore and contain only letters, numbers, and underscores!`,
    );
  }

  const fileName = `${userId}/${file.name}` ?? "";

  return getBingUserSets("Bing").then((userTestSet) => {
    if (userTestSet.map((_) => _.name).includes(fileName)) {
      throw new Error(`The file ${fileName} already exists.`);
    }

    return Promise.resolve(file);
  });
};

export const checkBingTestSet = (file: File) => {
  return verifyBingQuerySetFile(file);
};

export const getBingUserSetsAuthor = (filePath: string) => {
  const filePathSplit = filePath
    .replace(fileUrlPrefix_bingQuerySet_new + "/", "")
    .split("/");
  const guid = filePathSplit[0];

  if (store.account?.localAccountId === guid) {
    return store.account?.name ?? "You";
  }

  return "Others";
};
