import {
  Combobox,
  InfoLabel,
  Option,
  OptionGroup,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { TemplateType } from "sydneyeval-shared";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { updateSelectedTemplateAction } from "../../../actions/jobActions";
import type { JobTemplate } from "../../../models/JobTemplate";
import { getQuickFilterTemplates } from "../../../selectors/getQuickFilterTemplates";
import { getTemplateType } from "../../../selectors/getTemplateType";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dropdownMenu: {
      backgroundColor: "#F8F7F9",
    },
    listbox: {
      maxHeight: "300px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobEvaluationTemplateView = observer(() => {
  const styles = useStyles();

  const selectedTemplate = jobStore.selectedTemplate;
  const BingMetaData = jobCreationStore.bingMetaData;

  const getTemplateDisplayString = (template: JobTemplate | undefined) => {
    const templateId = template === undefined ? "" : template.Id;
    const templateName =
      template === undefined ? "" : `[${templateId}] ${template.Name}`;
    return templateName;
  };

  const [inputCache, setInputCache] = useState<string | undefined>(undefined);

  useEffect(() => {
    setInputCache(getTemplateDisplayString(selectedTemplate));
  }, [selectedTemplate]);

  const shouldGroupTemplates = computed(
    () =>
      BingMetaData != undefined &&
      BingMetaData.group_to_template != undefined &&
      !isFeatureEnabled("product-registration"),
  );
  const availableTemplates = getQuickFilterTemplates
    .get()
    .filter(
      (template) =>
        inputCache === undefined ||
        getTemplateDisplayString(template)
          .toLowerCase()
          .includes(inputCache.toLowerCase()),
    );

  const isBingProduct =
    getTemplateType.get() === TemplateType.BingV2 ||
    getTemplateType.get() === TemplateType.CWC;

  const renderTemplateOptions = () => {
    if (availableTemplates.length === 0) {
      return (
        <Option disabled key="no-results" text="">
          No results found
        </Option>
      );
    } else if (isBingProduct && shouldGroupTemplates.get()) {
      const finalGroup = new Map<string, JobTemplate[]>();
      const templatesGroup = BingMetaData?.group_to_template ?? [];
      Object.entries(templatesGroup).forEach(([key, value]) => {
        const current: JobTemplate[] = [];
        for (let i = 0; i < value?.length; i++) {
          availableTemplates
            .filter((template: JobTemplate) => template.Name === value[i])
            .forEach((template: JobTemplate) => current.push(template));
        }
        if (current.length > 0) {
          finalGroup.set(key, current);
        }
      });
      return [...finalGroup.entries()].map(
        ([key, value]: [string, JobTemplate[]]) => (
          <OptionGroup key={key} data-testid="mock-template-optiongroup">
            {key}
            {value.map((template) => (
              <Option
                key={template.Id}
                value={`${template.Id}`}
                data-testid="mock-template-option"
              >
                {getTemplateDisplayString(template)}
              </Option>
            ))}
          </OptionGroup>
        ),
      );
    } else {
      return availableTemplates.map((availableTemplate) => (
        <Option
          key={availableTemplate.Id}
          value={`${availableTemplate.Id}`}
          data-testid="mock-template-option"
        >
          {getTemplateDisplayString(availableTemplate)}
        </Option>
      ));
    }
  };

  return (
    <div className={styles.block}>
      <InfoLabel required>
        <span className={styles.blockTitle}>Evaluation Template</span>
      </InfoLabel>
      <Combobox
        className={styles.dropdownMenu}
        listbox={{ className: styles.listbox }}
        placeholder="Select a template"
        disabled={selectedTemplate === undefined}
        selectedOptions={[`${selectedTemplate?.Id}`]}
        onOpenChange={(_, data) => setInputCache(data.open ? "" : undefined)}
        value={inputCache ?? getTemplateDisplayString(selectedTemplate)}
        onChange={(ev) => {
          const value = ev.target.value;
          setInputCache(value.trim());
        }}
        onOptionSelect={(_, data) => {
          const currentSelectedTemplate = availableTemplates.find(
            (template) => `${template.Id}` === data.optionValue,
          );
          if (currentSelectedTemplate) {
            updateSelectedTemplateAction(currentSelectedTemplate);
          }
        }}
        data-testid="mock-template-combobox"
      >
        {renderTemplateOptions()}
      </Combobox>
    </div>
  );
});
