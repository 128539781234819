import { compact } from "lodash";
import { computed } from "mobx";
import { isFeatureEnabled } from "../../../selectors/features";
import type { JobTemplate } from "../models/JobTemplate";
import { jobStore } from "../store/jobStore";
import { productSettingsStore } from "../store/productSettingsStore";

const removeGeneratorTemplates = (templates: JobTemplate[]) => {
  return templates
    .filter(
      (_) =>
        ![
          "Bing_Test_Set_Generator",
          "BizChat-Personalized-Query-Set-Generator",
          "BizChat-Personalized-Query-Set-Generator_eyesoff",
          "BizChat-Personalized-Query-Set-Generator-V2",
          "BizChat-Personalized-Query-Set-Generator-V2_eyesoff",
        ].includes(_.ExperimentName),
    )
    .sort((a, b) => a.Name.localeCompare(b.Name));
};

export const getQuickFilterTemplates = computed(() => {
  if (!isFeatureEnabled("product-registration")) {
    const templates = (() => {
      switch (jobStore.quickTemplateTypeFilter) {
        case "All":
          return jobStore.availableTemplates;
        case "M365 Chat":
          return jobStore.availableTemplates.filter(
            (_) =>
              _.Type !== "BingV2" &&
              _.Type !== "CWC" &&
              _.Type !== "OPG" &&
              _.Type !== "GeneralSkipScraping",
          );
        case "Bing":
          return jobStore.availableTemplates.filter((_) => _.Type === "BingV2");
        case "CWC":
          return jobStore.availableTemplates.filter((_) => _.Type === "CWC");
        case "OPG":
          return jobStore.availableTemplates.filter((_) => _.Type === "OPG");
      }
    })();

    return removeGeneratorTemplates(templates);
  } else {
    const products = productSettingsStore.products;
    const productFilter = jobStore.productFilter;
    const templates = jobStore.availableTemplates;

    const targetProducts = (() => {
      if (
        productFilter === undefined ||
        productFilter === "All" ||
        productFilter === ""
      ) {
        return products;
      } else {
        return products.filter((_) => _.id === productFilter);
      }
    })();

    // Generate Product Templates based on products
    const productTemplates = targetProducts.flatMap((product) => {
      return compact(
        product.templates.map((template) => {
          const targetTemplate = templates.find(
            (_) => _.ExperimentName === template.pipelineExpName,
          );

          if (targetTemplate === undefined) {
            return undefined;
          }

          return {
            ...targetTemplate,
            Name: template.displayName,
            ProductId: product.id,
            ProductPipelineId: template.id,
          };
        }),
      );
    });

    return removeGeneratorTemplates(productTemplates);
  }
});
