import { createStore } from "satcheljs";
import type { JobPreValidationStep } from "../models/JobPreValidationStep";

type jobPreValidationStore = {
  jobPreValidationSteps: JobPreValidationStep[];
  JobPreValidationResolve: ((value: boolean) => void) | undefined;
  isDispatched: boolean;
  toastTitle: string;
  toastBody: string;
  progressbarValue: number;
  isCopyLinkClicked: boolean[];
  isFinishedVerifing: boolean;
  jobTemplateName: string | undefined;
};

export const initJobPreValidationStore: jobPreValidationStore = {
  jobPreValidationSteps: [
    {
      stepName: "control",
      status: "not started",
      statusCode: undefined,
      requestBody: "",
      responseBody: "",
      errorMessage: "",
      exp_config: "",
      scrap_user_id: "",
      sydney_url: "",
      client_request_id: "",
    },
    {
      stepName: "experiment",
      status: "not started",
      statusCode: undefined,
      requestBody: "",
      responseBody: "",
      errorMessage: "",
      exp_config: "",
      scrap_user_id: "",
      sydney_url: "",
      client_request_id: "",
    },
  ],
  isDispatched: false,
  toastTitle: "",
  toastBody: "",
  progressbarValue: -1,
  isCopyLinkClicked: [false, false],
  JobPreValidationResolve: undefined,
  isFinishedVerifing: false,
  jobTemplateName: "",
};

export const jobPreValidationStore = createStore<jobPreValidationStore>(
  "jobPreValidationStore",
  Object.assign({}, initJobPreValidationStore),
)();
