import { JobsResponse } from "../partners/OfflineEval/models/JobsResponse";
import type { MetricsChangeLog } from "../partners/OfflineEval/models/MetricsChangeLog";
import { MetricsChangeLogList } from "../partners/OfflineEval/models/MetricsChangeLog";

import type {
  AddJobPermissionRequestData,
  BingJobFileDownloadLinkRequestData,
  CallAvalonRequestData,
  CheckOnlineQuerySetContentRouterRequestData,
  CreateDetailsByJobIdRouterRequestData,
  CreateJobRouterRequestData,
  CreateScheduleJobRequestData,
  EditConfigRouterRequestData,
  ErrorNodeForFailedJobRouterRequestData,
  GetConfigSnapshotRouterRequestData,
  GetDynamicPermissionRouterRequestData,
  GetHeronJobOutputLinkRequestData,
  GetJobPermissionRequestData,
  GetOPGRAIResultRequestData,
  GetQuerySetContentRouterRequestData,
  GetSGMembersRequestData,
  JobPerfMetricRequestData,
  JobRouterRequestData,
  JobsRouterRequestData,
  ListConfigSnapshotRouterRequestData,
  PreSubmitJobRouterRequestData,
  ProductCreateRequestData,
  ProductDeleteRequestData,
  ProductUpdateRequestData,
  QueryMChatAllMetricsRouterParserRequestData,
  QueryMChatMetricsRouterParserRequestData,
  QuerySvalueRouterRequestData,
  RemoveJobPermissionRequestData,
  SearchScheduleJobData,
  SearchSGRouterRequestData,
  SetIncidentsRouterRequestData,
  SetTokenRouterRequestData,
  UpdateScheduleJobRequestData,
  VerifyTokenRouterRequestData,
} from "sydneyeval-shared";
import {
  anyObject,
  array,
  MetricJudgementRecord,
  none,
  optional,
  parseJsonStrWithDefault,
  PrivacyFilterTemplateContentArray,
  Product,
  ScheduledJobResponse,
  str,
  UserProfileResponse,
} from "sydneyeval-shared";
import { AppConfigurationSnapshotsResponse } from "../models/AppConfigurationSnapshot";
import { AvalonResponse } from "../models/Avalon";
import { Consent } from "../models/Consent";
import { HeronJobOutputLinkResponse } from "../models/HeronJobOutputLinkResponse";
import { IncidentList } from "../models/Incident";
import { Permission } from "../models/Permission";
import { QuerySetFile } from "../models/QuerySetFile";
import {
  SearchUserListResponse,
  SearchUserResponse,
  SubstrateSingleUserInfor,
} from "../models/SearchUserByIdResponse";
import { ErrorMessageResponse } from "../partners/OfflineEval/models/ErrorMessageResponse";
import { ErrorNodeResponse } from "../partners/OfflineEval/models/ErrorNodeResponse";
import { GeneralMetricsResponse } from "../partners/OfflineEval/models/GeneralMetricsResponse";
import { CreatedJobResponse } from "../partners/OfflineEval/models/Job";
import { JobOutputResponse } from "../partners/OfflineEval/models/JobOutputResponse";
import { JobSBSExpResponse } from "../partners/OfflineEval/models/JobSBSExpResponse";
import { JobTemplatesResponse } from "../partners/OfflineEval/models/JobTemplatesResponse";
import { KustoQueryResultResponse } from "../partners/OfflineEval/models/KustoPerfResult";
import { LMChecklistAssertionsResponse } from "../partners/OfflineEval/models/LMChecklistAssertionsResponse";
import { LMChecklistDetailsResponse } from "../partners/OfflineEval/models/LMChecklistDetailsResponse";
import { OPGRAIResult } from "../partners/OfflineEval/models/OPGRAIResult";
import { UtteranceConversationId } from "../partners/OfflineEval/models/UtteranceConversationId";
import {
  MChatMetricsByHashResponse,
  MChatMetricsByMetricsResponse,
  MChatSValueResponse,
} from "../partners/OfflineEval/models/UtteranceResponse";
import { VerifyTokenResponse } from "../partners/OfflineEval/models/VerifyTokenResponse";
import {
  cleanLocalAvalonAccessTokenOnError,
  cleanLocalHeronAccessTokenOnError,
  cleanLocalSubstrateTokenOnError,
  getAvalonAccessToken,
} from "./accessTokenHelper";
import { createRequest } from "./createRequest";
import { getBasicHeader } from "./headerHelper";

const convertToQueryString = (query: Record<string, string>) => {
  return Object.entries(query)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");
};

export interface IUploadConsumerJobParams {
  JobName: string;
  RequestId: string;
  CreatedBy: string;
  CreatorSmtpAddress: string;
  JsonConfig: string;
}

export interface IDeleteJobParams {
  CreatorSmtpAddress: string;
}

export type JobStatusType =
  | "Running"
  | "Created"
  | "Submitted"
  | "Completed"
  | "Failed"
  | "SubmitFailed"
  | "Cancelled"
  | "Deleted";

interface IUpdateJobStatusParams {
  JobId: number;
  Status: JobStatusType;
}

interface IDeleteTokenParams {
  SecretName: string;
}

interface ISearchUserByIdParams {
  SubstrateToken: string;
  UserIds: string[];
  CallerFileNameLine: string;
  ClientRequestId: string;
  ProcessName: string;
}

interface IGetUtteranceConversationId {
  JobId: string;
  Query: string;
  ExperimentName: string;
}

interface getLMChecklistDetailsByJobIdParams {
  JobId: string;
}

interface IRerunJobParams {
  ID: number;
}

interface IGetMetricsByJobIdParams {
  JobId: string;
}

interface IUploadQuerySetParams {
  File: File;
  FileName: string;
  FileType: "Bing" | "MChat";
}

interface IUploadFolderParams {
  remoteFolderName: string;
  folder: File[];
  localFolderName: string;
}

interface ISetConsentParams {
  Consent: Consent;
  User: string;
}

interface IGetConsentParams {
  User: string;
}

interface ISetMetricsChangeParams {
  MetricsChange: MetricsChangeLog[] | undefined;
}

interface IGetLMChecklistAssertionParams {
  JobId: string;
}

interface IGetErrorMessageForFailedJobParams {
  readonly JobId: string;
  readonly JobTemplateType: string;
}

interface IGetKeyVaultSecretByKeyParams {
  readonly SecretName: string;
}

interface ISetKeyVaultSecretByKeyParams {
  readonly SecretName: string;
  readonly SecretValue: string;
}

export const getPublicQuerySets = () =>
  createRequest({
    api: "/getquerysets?folder=public",
    typeGuard: array(QuerySetFile),
  });

export const getCustomQuerySets = () =>
  createRequest({
    api: "/getquerysets",
    typeGuard: array(QuerySetFile),
  });

export const getPublicLMChecklistSets = () =>
  createRequest({
    api: "/getquerysets?folder=lmchecklist/public",
    typeGuard: array(QuerySetFile),
  }).then((files) => files.map((file) => file.name));

export const getCustomLMChecklistSets = () =>
  createRequest({
    api: "/getquerysets?folder=lmchecklist",
    typeGuard: array(QuerySetFile),
  }).then((files) => files.map((file) => file.name));

export const getBingUserSets = (type: "Bing" | "CWC") =>
  createRequest({
    api: `/getBingQuerySets?type=${type}`,
    typeGuard: array(QuerySetFile),
  });

export const getQuerySetGenerations = () =>
  createRequest({
    api: "/getQuerySetGenerations",
    typeGuard: array(QuerySetFile),
  });

export const getBingQuerySetGenerations = () =>
  createRequest({
    api: "/getBingQuerySetGenerations",
    typeGuard: array(QuerySetFile),
  });

export const checkQuerySetHeader = (fileName: string) =>
  createRequest({
    api: `/checkQuerySetHeader?filename=${encodeURIComponent(fileName)}`,
    typeGuard: str,
  });

export const checkOnlineQuerySetContent = (
  param: CheckOnlineQuerySetContentRouterRequestData,
) =>
  createRequest({
    api: `/checkOnlineQuerySetContent`,
    requestData: param,
    typeGuard: none,
  });

export const checkLocalQuerySetContent = async (file: File) =>
  createRequest({
    api: "/checkLocalQuerySetContent",
    requestData: (() => {
      const formData = new FormData();
      formData.append("file", file);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const getQuerySetContent = (
  query: GetQuerySetContentRouterRequestData,
) =>
  createRequest({
    api: `/getQuerySetContent?${convertToQueryString(query)}`,
    typeGuard: str,
  });

export const deleteQuerySet = (params: { file: string; source: string }) =>
  createRequest({
    api: `/deleteStorageFile`,
    requestData: params,
    typeGuard: none,
  });

export const getJob = (query: JobRouterRequestData) =>
  createRequest({
    api: `/job?${convertToQueryString(query)}`,
    typeGuard: JobsResponse,
  });

export const getJobOutput = (jobId: string) =>
  createRequest({
    api: `/getJobOutput?jobId=${encodeURIComponent(jobId)}`,
    typeGuard: JobOutputResponse,
  });

export const getJobOutputFileContent = (fileName: string) =>
  createRequest({
    api: `/getJobOutputFileContent?filename=${encodeURIComponent(fileName)}`,
    typeGuard: str,
  });

export const getHeronJobOutputLink = (
  params: GetHeronJobOutputLinkRequestData,
) =>
  cleanLocalHeronAccessTokenOnError(
    createRequest({
      api: `/getHeronJobOutputLink`,
      requestData: params,
      typeGuard: HeronJobOutputLinkResponse,
    }),
  );

export const getBingAMLFileDownloadLink = (
  query: BingJobFileDownloadLinkRequestData,
) =>
  createRequest({
    api: `/getBingAMLFileDownloadLink?${convertToQueryString(query)}`,
    typeGuard: str,
  });

export const getAzureMLFileContent = (path: string) =>
  createRequest({
    api: `/getAzureMLFileContent`,
    requestData: { AzureMLPath: path },
    typeGuard: str,
  });

export const getJobTemplates = () =>
  createRequest({
    api: "/jobtemplates",
    requestData: {},
    typeGuard: JobTemplatesResponse,
  }).then((_) => _.recordset);

export const getJobs = (params: JobsRouterRequestData) =>
  createRequest({
    api: "/jobs",
    requestData: params,
    typeGuard: JobsResponse,
  });

export const getBingProductMetaData = (type: "Bing" | "CWC") => {
  if (type === "Bing") {
    return createRequest({
      api: "/getBingMetaData",
      typeGuard: str,
    });
  }
  return createRequest({
    api: "/getCWCMetaData",
    typeGuard: str,
  });
};

export const getPrivacyFilterData = (jobId: string) =>
  createRequest({
    api: `/getPrivacyFilterFileContent?filename=${encodeURIComponent(
      "privacyFilter/" + jobId + "/redacted_output.csv",
    )}`,
    typeGuard: PrivacyFilterTemplateContentArray,
  });

export const createJob = (params: CreateJobRouterRequestData) =>
  createRequest({
    api: "/createjob",
    requestData: params,
    typeGuard: CreatedJobResponse,
  });

export const preSubmitJob = (params: PreSubmitJobRouterRequestData) =>
  createRequest({
    api: "/preSubmitJob",
    requestData: params,
    typeGuard: anyObject,
  });

export const rerunJob = (params: IRerunJobParams) =>
  createRequest({
    api: "/rerunjob",
    requestData: params,
    typeGuard: none,
  });

export const deleteJobs = (params: IDeleteJobParams) =>
  createRequest({
    api: "/deletejobs",
    requestData: params,
    typeGuard: none,
  });

export const updateJobStatus = (params: IUpdateJobStatusParams) =>
  createRequest({
    api: "/updateJobStatus",
    requestData: params,
    typeGuard: none,
  });

export const verifyToken = (params: VerifyTokenRouterRequestData) =>
  createRequest({
    api: "/verifyToken",
    requestData: params,
    typeGuard: VerifyTokenResponse,
  });

export const setToken = (params: SetTokenRouterRequestData) =>
  createRequest({
    api: "/setToken",
    requestData: params,
    typeGuard: none,
  });

export const getAriaToken = () =>
  createRequest({
    api: "/getAriaToken",
    requestData: {},
    typeGuard: str,
    dataBag: {
      skipTelemetry: true,
    },
  });

export const deleteToken = (params: IDeleteTokenParams) =>
  createRequest({
    api: "/deleteToken",
    requestData: params,
    typeGuard: none,
  });

export const getDetailsByJobId = (
  params: CreateDetailsByJobIdRouterRequestData,
) =>
  createRequest({
    api: "/detailsByJobId",
    requestData: params,
    typeGuard: array(MetricJudgementRecord),
  });

export const getUtteranceConversationId = (
  params: IGetUtteranceConversationId,
) =>
  createRequest({
    api: "/utteranceConversationId",
    requestData: params,
    typeGuard: array(UtteranceConversationId),
    dataBag: {
      jobId: params.JobId,
      expName: params.ExperimentName,
    },
  });

export const getMetricsByJobId = (params: IGetMetricsByJobIdParams) =>
  createRequest({
    api: "/metricsByJobId",
    requestData: params,
    typeGuard: GeneralMetricsResponse,
  });

export const getLMChecklistDetails = (
  params: getLMChecklistDetailsByJobIdParams,
) =>
  createRequest({
    api: "/lmchecklistDetailsByJobId",
    requestData: params,
    typeGuard: optional(LMChecklistDetailsResponse),
  });

export const getLMChecklistAssertions = (
  params: IGetLMChecklistAssertionParams,
) =>
  createRequest({
    api: "/lmchecklistAssertions",
    requestData: params,
    typeGuard: optional(LMChecklistAssertionsResponse),
  });

export const getManagedIdentityToken = (scope: string) =>
  createRequest({
    api: "/getManagedIdentityToken",
    requestData: { Scope: scope },
    typeGuard: anyObject,
  });

export const uploadQuerySet = async (params: IUploadQuerySetParams) =>
  createRequest({
    api: "/uploadQuerySet",
    requestData: (() => {
      const formData = new FormData();
      formData.append("file", params.File);
      formData.append("fileName", params.FileName);
      formData.append("fileType", params.FileType);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadOPGFolder = async (params: IUploadFolderParams) =>
  createRequest({
    api: "/uploadOPGFolder",
    requestData: (() => {
      const formData = new FormData();
      Array.from(params.folder).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("folderName", params.remoteFolderName);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadBingSkipScrapingFolder = async (
  params: IUploadFolderParams,
) =>
  createRequest({
    api: "/uploadBingSkipScrapingFolder",
    requestData: (() => {
      const formData = new FormData();
      Array.from(params.folder).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("folderName", params.remoteFolderName);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadCWCSkipScrapingFolder = async (
  params: IUploadFolderParams,
) =>
  createRequest({
    api: "/uploadCWCSkipScrapingFolder",
    requestData: (() => {
      const formData = new FormData();
      Array.from(params.folder).forEach((file) => {
        formData.append("files", file);
      });
      formData.append("folderName", params.remoteFolderName);
      return formData;
    })(),
    typeGuard: none,
    headers: {
      ...(await getBasicHeader()),
      "Content-Type": "multipart/form-data",
    },
  });

export const setConsent = (params: ISetConsentParams) =>
  createRequest({
    api: "/consent/set",
    requestData: (() => {
      return {
        Consent: JSON.stringify(params.Consent),
        User: params.User,
      };
    })(),
    typeGuard: none,
  });

export const getConsent = (params: IGetConsentParams) =>
  createRequest({
    api: "/consent/get",
    requestData: params,
    typeGuard: str,
  }).then((_) => {
    return parseJsonStrWithDefault(_, Consent, { Version: 0 });
  });

export const addJobPermission = (params: AddJobPermissionRequestData) =>
  createRequest({
    api: "/job/permission/add",
    requestData: params,
    typeGuard: none,
  });

export const removeJobPermission = (params: RemoveJobPermissionRequestData) =>
  createRequest({
    api: "/job/permission/remove",
    requestData: params,
    typeGuard: none,
  });

export const getJobPermission = (params: GetJobPermissionRequestData) =>
  createRequest({
    api: "/job/permission/get",
    requestData: params,
    typeGuard: array(str),
  });

export const setIncident = (params: SetIncidentsRouterRequestData) =>
  createRequest({
    api: "/incident/set",
    requestData: params,
    typeGuard: none,
  });

export const getIncident = () =>
  createRequest({
    api: "/incident/get",
    typeGuard: IncidentList,
  });

export const setMetricsChangeLog = (params: ISetMetricsChangeParams) =>
  createRequest({
    api: "/metricschange/set",
    requestData: params,
    typeGuard: none,
  });

export const getMetricsChangeLog = () =>
  createRequest({
    api: "/metricschange/get",
    typeGuard: str,
  }).then((_) => {
    return parseJsonStrWithDefault(_, MetricsChangeLogList, undefined);
  });

export const getKeyVaultSecretByKey = (params: IGetKeyVaultSecretByKeyParams) =>
  createRequest({
    api: "/migration/getKeyVaultSecretByKey",
    requestData: params,
    typeGuard: str,
    dataBag: {
      skipSuccessRate: true,
    },
  });

export const sendRevokeConsentNotification = () =>
  createRequest({
    api: "/sendRevokeNotification",
    typeGuard: str,
  });

export const setKeyVaultSecretByKey = (params: ISetKeyVaultSecretByKeyParams) =>
  createRequest({
    api: "/migration/setKeyVaultSecretByKey",
    requestData: params,
    typeGuard: none,
  });

export const uploadConsumerJobJson = (params: IUploadConsumerJobParams) =>
  createRequest({
    api: "/createConsumerJob",
    requestData: params,
    typeGuard: none,
  });

export const getDynamicPermission = (
  params: GetDynamicPermissionRouterRequestData,
) =>
  cleanLocalSubstrateTokenOnError(
    createRequest({
      api: "/me/dynamicpermission",
      requestData: params,
      typeGuard: Permission,
    }),
  );

export const getErrorMessageForFailedJob = (
  params: IGetErrorMessageForFailedJobParams,
) =>
  createRequest({
    api: "/getErrorMessageForFailedJob",
    requestData: params,
    typeGuard: ErrorMessageResponse,
  });

export const getErrorNodeForFailedJob = (
  params: ErrorNodeForFailedJobRouterRequestData,
) =>
  createRequest({
    api: "/getErrorNodeForFailedJob",
    requestData: params,
    typeGuard: ErrorNodeResponse,
  });

export const getScheduledJobs = (param: SearchScheduleJobData) =>
  createRequest({
    api: "/scheduler/list",
    requestData: param,
    typeGuard: ScheduledJobResponse,
  });

export const createScheduledJob = (params: CreateScheduleJobRequestData) =>
  createRequest({
    api: "/scheduler/create",
    requestData: params,
    typeGuard: none,
  });

export const updateScheduledJob = (params: UpdateScheduleJobRequestData) =>
  createRequest({
    api: "/scheduler/update",
    requestData: params,
    typeGuard: none,
  });

export const searchUserById = (params: ISearchUserByIdParams) =>
  cleanLocalSubstrateTokenOnError(
    createRequest({
      api: "/searchUserById",
      requestData: params,
      typeGuard: SearchUserResponse,
    }),
  );

export const getSevalUserList = (
  params: GetDynamicPermissionRouterRequestData,
) =>
  cleanLocalSubstrateTokenOnError(
    createRequest({
      api: "/getSevalUserList",
      requestData: params,
      typeGuard: SearchUserListResponse,
    }),
  );

export const searchUserSelfProfile = () =>
  createRequest({
    api: "/searchUserSelfProfile",
    typeGuard: UserProfileResponse,
  });

export const getJobSBSExp = (params: { JobId: string }) =>
  createRequest({
    api: "/getJobSBSExp",
    requestData: params,
    typeGuard: JobSBSExpResponse,
  });

export const getPerfMetricsQueryResult = (params: { Query: string }) =>
  createRequest({
    api: "/getPerfMetricsQueryResult",
    requestData: params,
    typeGuard: anyObject,
  });

export const getOPGRAIResult = (params: GetOPGRAIResultRequestData) =>
  createRequest({
    api: "/getOPGRAIResult",
    requestData: params,
    typeGuard: OPGRAIResult,
  });

export const getJobPerfMetric = (params: JobPerfMetricRequestData) =>
  createRequest({
    api: "/jobPerfMetric",
    requestData: params,
    typeGuard: KustoQueryResultResponse,
  });

export const getBingClusters = (
  params: GetDynamicPermissionRouterRequestData,
) =>
  createRequest({
    api: "/getBingClusters",
    requestData: params,
    typeGuard: array(str),
  });

export const getSGMembers = (params: GetSGMembersRequestData) =>
  createRequest({
    api: "/getSGMembers",
    requestData: params,
    typeGuard: SearchUserListResponse,
  });

export const searchSGById = (params: SearchSGRouterRequestData) =>
  createRequest({
    api: "/searchSGById",
    requestData: params,
    typeGuard: SubstrateSingleUserInfor,
  });

export const queryMChatMetricsbyMetrics = (
  params: QueryMChatMetricsRouterParserRequestData,
) =>
  createRequest({
    api: "/queryMChatMetrics/byMetrics",
    requestData: params,
    typeGuard: MChatMetricsByMetricsResponse,
  });

export const queryMChatMetricsbyHash = (
  params: QueryMChatAllMetricsRouterParserRequestData,
) =>
  createRequest({
    api: "/queryMChatMetrics/byHash",
    requestData: params,
    typeGuard: MChatMetricsByHashResponse,
  });

export const queryMChatSValue = (params: QuerySvalueRouterRequestData) =>
  createRequest({
    api: "/queryMChatSValue",
    requestData: params,
    typeGuard: MChatSValueResponse,
  });

export const editConfiguration = (params: EditConfigRouterRequestData) =>
  createRequest({
    api: "/configuration/edit",
    requestData: params,
    typeGuard: none,
  });

export const listConfiguration = (
  params: ListConfigSnapshotRouterRequestData,
) =>
  createRequest({
    api: "/configuration/list",
    requestData: params,
    typeGuard: AppConfigurationSnapshotsResponse,
  });

export const getConfiguration = (params: GetConfigSnapshotRouterRequestData) =>
  createRequest({
    api: "/configuration/get",
    requestData: params,
    typeGuard: str,
  });

export const listProducts = () =>
  createRequest({ api: "/product/list", typeGuard: array(Product) });

export const createProduct = (params: ProductCreateRequestData) =>
  createRequest({
    api: "/product/create",
    requestData: params,
    typeGuard: array(Product),
  });

export const deleteProduct = (params: ProductDeleteRequestData) =>
  createRequest({
    api: "/product/delete",
    requestData: params,
    typeGuard: array(Product),
  });

export const updateProduct = (params: ProductUpdateRequestData) =>
  createRequest({
    api: "/product/update",
    requestData: params,
    typeGuard: array(Product),
  });

export const getAvalonResponse = async (params: CallAvalonRequestData) => {
  const basicHeaders = await getBasicHeader();

  if (params.AvalonAccessToken === undefined) {
    const avalonToken = await getAvalonAccessToken().catch(() => undefined);
    params.AvalonAccessToken = avalonToken;
  }

  return cleanLocalAvalonAccessTokenOnError(
    createRequest({
      api: "/callAvalon",
      requestData: params,
      typeGuard: AvalonResponse,
      headers: basicHeaders,
    }),
  );
};
