import { getUtteranceConversationId } from "./apiHelper";

export type DevUILink = {
  conversationId: string;
  timeStamp: string;
  link: string;
};
export const openDevUILink = (
  query: string,
  jobId: string,
  exp_name: string,
): Promise<DevUILink> => {
  return getUtteranceConversationId({
    JobId: jobId,
    Query: query.replaceAll("'", "''"),
    ExperimentName: exp_name,
  }).then((data) => {
    if (data.length === 0) {
      throw new Error("No conversation record found.");
    }

    const conversationId = data[0].conversationId;

    const timeStamp = data[0].time;

    const link = `https://www.aka.ms/bizchatdevui?sourceConversationId=${conversationId}&timeStamp=${timeStamp}&clientName=seval`;

    return {
      conversationId,
      timeStamp,
      link,
    };
  });
};
