import {
  Badge,
  Body1,
  Button,
  Field,
  makeStyles,
  shorthands,
  Switch,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import {
  resetFeatureFlagOverridesAction,
  setFeatureFlagOverrideAction,
} from "../../actions/featureFlagActions";
import {
  getFeatureFlagStatusIcon,
  isFeatureEnabled,
} from "../../selectors/features";
import {
  isAdmin,
  isDev,
  isDRI,
  isIncidentCreator,
} from "../../selectors/userPermission";

import { observer } from "mobx-react-lite";
import React from "react";
import { ClientFeatureFlags, ClientFeatureFlagScopes } from "sydneyeval-shared";
import {
  resetAppConfiguration,
  selectAppConfigurationFile,
} from "../../actions/appConfigurationActions";
import { registeredDebugGroupContributions } from "../../contribution/register";
import { getAppEnv } from "../../helpers/appEnvHelper";
import { cleanAuthOverrides } from "../../helpers/cacheHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { closeSidePane } from "../../mutators/sidePaneMutators";
import { updateSelectedContributionDialogId } from "../../mutators/updateContributions";
import { store } from "../../store/store";
import { AppConfigurationDialog } from "../Dialog/AppConfigurationDialog";
import { AuthOverrideDialog } from "../Dialog/AuthOverrideDialog";
import { IncidentListDialog } from "../Dialog/IncidentListDialog";
import { IncidentSettingDialog } from "../Dialog/IncidentSettingDialog";
import { KeyVaultUpdatingDialog } from "../Dialog/KeyVaultUpdatingDialog";
import { KustoQueryDialog } from "../Dialog/KustoQueryDialog";
import { TokenGeneratingDialog } from "../Dialog/TokenGeneratingDialog";

const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .fui-Field": {
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
      ...shorthands.padding("10px"),
      ...shorthands.border("1px", "solid"),
      ...shorthands.borderColor(tokens.colorNeutralStroke1),
    },
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    ...shorthands.margin("0", "0", "0", "9px"),
  },
  subtitle: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  content: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    ...shorthands.gap("4px"),
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: "8px",
  },
});

const ButtonGroupWrapper = (props: {
  isEnabled: boolean;
  title: string;
  children: React.ReactNode;
}) => {
  const styles = useStyles();
  if (!props.isEnabled) {
    return null;
  }

  return (
    <Field className={styles.subtitle} label={props.title}>
      <div className={styles.buttonWrapper}>{props.children}</div>
    </Field>
  );
};

export const DevCenter = observer(() => {
  const styles = useStyles();

  const [isIncidentSettingDialogOpen, setIsIncidentSettingDialogOpen] =
    React.useState(false);
  const [isIncidentListDialogOpen, setIsIncidentListDialogOpen] =
    React.useState(false);
  const [isKeyVaultUpdatingDialogOpen, setIsKeyVaultUpdatingDialogOpen] =
    React.useState(false);
  const [
    isGenerateManagedIdentityTokenDialogOpen,
    setIsGenerateManagedIdentityTokenDialogOpen,
  ] = React.useState(false);
  const [
    isGenerateUserAccessTokenDialogOpen,
    setIsGenerateUserAccessTokenDialogOpen,
  ] = React.useState(false);
  const [
    isGenerateUserAccessTokenPopupDialogOpen,
    setIsGenerateUserAccessTokenPopupDialogOpen,
  ] = React.useState(false);
  const [
    isGenerateUserRefreshTokenDialogOpen,
    setIsGenerateUserRefreshTokenDialogOpen,
  ] = React.useState(false);

  const [isKustoQueryDialogOpen, setIsKustoQueryDialogOpen] =
    React.useState(false);

  const [isAuthOverrideDialogOpen, setIsAuthOverrideDialogOpen] =
    React.useState(false);

  const [isAppConfigurationDialogOpen, setIsAppConfigurationDialogOpen] =
    React.useState(false);

  const createButton = (text: string, onClick: () => void) => (
    <Button onClick={onClick}>{text}</Button>
  );

  return (
    <div className={styles.root}>
      <div className={styles.titleRow}>
        <div
          className={styles.backButton}
          role="button"
          aria-label="Back Button"
          onClick={() => {
            closeSidePane();
          }}
        >
          <BackIcon />
        </div>
        <Body1 className={styles.title}>{`SEVAL Dev Center`}</Body1>
      </div>
      <div className={styles.content}>
        <Field className={styles.subtitle} label={"Account Permissions"}>
          <div className={styles.tags}>
            {Object.entries(store.permission ?? {})
              .filter((_) => _[1])
              .map(([k, v]) => {
                return (
                  <Badge
                    key={k}
                    color="success"
                    shape="rounded"
                    appearance="tint"
                  >
                    {`${k}:${v}`}
                  </Badge>
                );
              })}

            {Object.entries(store.permission ?? {})
              .filter((_) => !_[1])
              .map(([k, v]) => {
                return (
                  <Badge
                    key={k}
                    color="severe"
                    shape="rounded"
                    appearance="tint"
                  >
                    {`${k}:${v}`}
                  </Badge>
                );
              })}
          </div>
        </Field>

        <Field className={styles.subtitle} label={"Feature Flags Overrides"}>
          {ClientFeatureFlags.filter(([feature]) => {
            const scope = ClientFeatureFlagScopes[feature];
            if (scope === undefined) {
              return true;
            }

            return scope.includes(getAppEnv().type);
          }).map(([feature]) => {
            return (
              <Switch
                id={feature}
                key={feature}
                checked={isFeatureEnabled(feature)}
                label={
                  <div className={styles.switch}>
                    {getFeatureFlagStatusIcon(feature)}
                    <Body1>{feature}</Body1>
                  </div>
                }
                onChange={() => {
                  const isEnabled = !isFeatureEnabled(feature);
                  setFeatureFlagOverrideAction(feature, isEnabled);
                  telemetryHelper.logDevEvent("OverrideFeatureFlag", {
                    feature,
                    isEnabled,
                  });
                }}
              />
            );
          })}
          <Button
            onClick={() => {
              resetFeatureFlagOverridesAction();
              telemetryHelper.logDevEvent("ResetFeatureFlagOverrides");
            }}
          >
            Reset Local Override
          </Button>
        </Field>

        <ButtonGroupWrapper isEnabled={isDev.get()} title={"Access Token"}>
          {createButton("Get User Access Token (Slient)", () => {
            setIsGenerateUserAccessTokenDialogOpen(true);
          })}
          {createButton("Get User Access Token (Popup)", () => {
            setIsGenerateUserAccessTokenPopupDialogOpen(true);
          })}
          {createButton("Get User Refresh Token", () => {
            setIsGenerateUserRefreshTokenDialogOpen(true);
          })}
        </ButtonGroupWrapper>

        <ButtonGroupWrapper
          isEnabled={isAdmin.get()}
          title={"Token Generating"}
        >
          {createButton("Get Managed Identity Token", () => {
            setIsGenerateManagedIdentityTokenDialogOpen(true);
          })}
          {createButton("Override Auth", () => {
            setIsAuthOverrideDialogOpen(true);
          })}
          {createButton("Clean Auth Overrides", () => {
            cleanAuthOverrides();
          })}
        </ButtonGroupWrapper>

        <ButtonGroupWrapper isEnabled={isAdmin.get()} title={"Kusto"}>
          {createButton("Run Perf Metrics Query", () => {
            setIsKustoQueryDialogOpen(true);
          })}
        </ButtonGroupWrapper>

        <ButtonGroupWrapper
          isEnabled={isIncidentCreator.get()}
          title={"Incidents"}
        >
          {createButton("Add Incident", () => {
            setIsIncidentSettingDialogOpen(true);
          })}
          {createButton("Manage Incidents", () => {
            setIsIncidentListDialogOpen(true);
          })}
        </ButtonGroupWrapper>

        <ButtonGroupWrapper
          isEnabled={isIncidentCreator.get()}
          title={"App Configuration"}
        >
          {createButton("Edit App Configuration", () => {
            resetAppConfiguration();
            setIsAppConfigurationDialogOpen(true);
            selectAppConfigurationFile("aml_eyeson_staging");
          })}
        </ButtonGroupWrapper>

        {registeredDebugGroupContributions.map((_) => (
          <ButtonGroupWrapper
            key={_.guid}
            isEnabled={_.isEnabled()}
            title={_.groupName}
          >
            {_.debugContributions.map((debugContribution) => {
              switch (debugContribution.type) {
                case "click":
                  return createButton(
                    debugContribution.text(),
                    debugContribution.onClick,
                  );

                case "dialog":
                  return createButton(
                    debugContribution.text(),
                    updateSelectedContributionDialogId.bind(
                      null,
                      debugContribution.guid,
                    ),
                  );
              }
            })}
          </ButtonGroupWrapper>
        ))}

        <ButtonGroupWrapper isEnabled={isAdmin.get()} title="Admin Operations">
          {createButton("Update KeyVault Secret Value", () => {
            setIsKeyVaultUpdatingDialogOpen(true);
          })}
        </ButtonGroupWrapper>
      </div>

      {isAdmin.get() && (
        <TokenGeneratingDialog
          type="ManagedIdentityToken"
          isOpen={isGenerateManagedIdentityTokenDialogOpen}
          onComplete={() => {
            setIsGenerateManagedIdentityTokenDialogOpen(false);
          }}
        />
      )}
      {isDev.get() && (
        <TokenGeneratingDialog
          type="UserAccessToken"
          isOpen={isGenerateUserAccessTokenDialogOpen}
          onComplete={() => {
            setIsGenerateUserAccessTokenDialogOpen(false);
          }}
        />
      )}
      {isDev.get() && (
        <TokenGeneratingDialog
          type="UserAccessTokenPopup"
          isOpen={isGenerateUserAccessTokenPopupDialogOpen}
          onComplete={() => {
            setIsGenerateUserAccessTokenPopupDialogOpen(false);
          }}
        />
      )}
      {isDev.get() && (
        <TokenGeneratingDialog
          type="UserRefreshToken"
          isOpen={isGenerateUserRefreshTokenDialogOpen}
          onComplete={() => {
            setIsGenerateUserRefreshTokenDialogOpen(false);
          }}
        />
      )}
      {isAdmin.get() && (
        <KustoQueryDialog
          isOpen={isKustoQueryDialogOpen}
          onComplete={() => {
            setIsKustoQueryDialogOpen(false);
          }}
        />
      )}
      {isAdmin.get() && (
        <AuthOverrideDialog
          isOpen={isAuthOverrideDialogOpen}
          onComplete={() => {
            setIsAuthOverrideDialogOpen(false);
          }}
        />
      )}
      {isIncidentCreator.get() && (
        <IncidentSettingDialog
          isOpen={isIncidentSettingDialogOpen}
          onComplete={() => {
            setIsIncidentSettingDialogOpen(false);
          }}
        />
      )}
      {isIncidentCreator.get() && (
        <IncidentListDialog
          isOpen={isIncidentListDialogOpen}
          onComplete={() => {
            setIsIncidentListDialogOpen(false);
          }}
        />
      )}
      {isAdmin.get() && (
        <KeyVaultUpdatingDialog
          isOpen={isKeyVaultUpdatingDialogOpen}
          onComplete={() => {
            setIsKeyVaultUpdatingDialogOpen(false);
          }}
        />
      )}
      {isDRI.get() && (
        <AppConfigurationDialog
          isOpen={isAppConfigurationDialogOpen}
          onComplete={() => {
            setIsAppConfigurationDialogOpen(false);
          }}
        />
      )}
    </div>
  );
});
