export const generateFormattedDateString = (): string => {
  const now = new Date();

  // Get the date components
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");

  // Get the time components
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  // Concatenate the components to form the desired format
  const formattedString = `${year}${month}${day}${hours}${minutes}${seconds}`;

  return formattedString;
};

export const generateFormattedDateStringForQuerySet = (date: Date): string => {
  // Get the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Get the time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Concatenate the components to form the desired format
  const formattedString = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedString;
};
export const datetimeLocalFormat = (isoString: string | undefined): string => {
  if (isoString === undefined) {
    return "";
  }
  const datetime = new Date(isoString);

  // Get the date components
  const year = datetime.getFullYear();
  const month = String(datetime.getMonth() + 1).padStart(2, "0");
  const day = String(datetime.getDate()).padStart(2, "0");

  // Get the time components
  const hours = String(datetime.getHours()).padStart(2, "0");
  const minutes = String(datetime.getMinutes()).padStart(2, "0");

  // Concatenate the components to form the desired format
  const formattedString = `${year}-${month}-${day}T${hours}:${minutes}:00`;

  return formattedString;
};

export const getCurrentLocalDatetime = (): string => {
  return new Date().toISOString();
};
export const get3MonthLaterDate = (startTime: string) => {
  const currentDate = new Date(startTime);
  currentDate.setMonth(currentDate.getMonth() + 3);
  return currentDate.toISOString();
};

export const convertLocalDatetimeToUTC = (datetimeStr: string): string => {
  const datetime = new Date(datetimeStr);

  const year = datetime.getUTCFullYear();
  const month = String(datetime.getUTCMonth() + 1).padStart(2, "0");
  const day = String(datetime.getUTCDate()).padStart(2, "0");

  // Get the time components
  const hours = String(datetime.getUTCHours()).padStart(2, "0");
  const minutes = String(datetime.getUTCMinutes()).padStart(2, "0");

  // Concatenate the components to form the desired format
  const formattedString = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
  return formattedString;
};

export const getCurrentTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const checkUrlValidation = (url: string): boolean => {
  const urlPattern = /^(ftp|http|https|wss):\/\/[^ "]+$/;
  return urlPattern.test(url);
};

export const checkValidAvalonSydneyURL = (
  url?: string,
): boolean | undefined => {
  return url?.includes("m365Copilot");
};

export const checkVariantValidation = (variant: string): boolean => {
  return /^[\w]*([,.][\w]*)*$/.test(variant);
};

export const checkVariantsForTemporalQueries = (
  variants?: string,
): boolean | undefined => {
  const variantsArray = variants?.split(",");
  return (
    variantsArray?.includes("feature.IsFullDebug3SEnabled") &&
    variantsArray?.includes("feature.EnableUseRelativeDateTimeForSearchResults")
  );
};

// Valid header reference:
// https://dev.azure.com/msasg/Cortana/_wiki/wikis/M365%20Chat.wiki/145841/How-is-offline-evaluation-query-set-generated-from-eval-query-set-?anchor=offline-evaluation-query-set-schemas
export const checkHeaderValidation = (firstline: string): boolean => {
  const columns = firstline.split("\t");
  const queryCount = columns.filter(
    (_) => _.toLocaleLowerCase() === "query",
  ).length;
  const utteranceCount = columns.filter(
    (_) => _.toLocaleLowerCase() === "utterance",
  ).length;
  const hasQueryOrUtterance = queryCount + utteranceCount === 1;
  const hasSegment = columns.filter(
    (_) => _.toLocaleLowerCase() === "segment",
  ).length;
  const hasSegment2 = columns.filter(
    (_) => _.toLocaleLowerCase() === "segment 2",
  ).length;
  const hasSegmentOrSegment2 = hasSegment + hasSegment2 === 1;
  return hasQueryOrUtterance && hasSegmentOrSegment2;
};

export const formatCreateTime = (time: string | undefined) => {
  if (time === undefined) {
    return "";
  }
  const inputDateTime = new Date(time);
  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });
  return dateFormatter.format(inputDateTime);
};

export const getTimeDuration = (start: string, end: string) => {
  const startTime = new Date(start);
  const endTime = new Date(end);
  const duration = endTime.getTime() - startTime.getTime();
  const hours = Math.floor(duration / 3600000);
  const minutes = Math.floor((duration % 3600000) / 60000);
  const seconds = Math.floor((duration % 60000) / 1000);
  if (duration > 3600000) {
    return `${hours}:${minutes}:${seconds}`;
  } else if (duration > 60000) {
    return `${minutes}:${seconds}`;
  } else {
    return `${seconds}`;
  }
};

export const capitalFirstLetter = (str: string) => {
  return (str.charAt(0).toUpperCase() + str.slice(1)).replaceAll("_", " ");
};

export const bingRequestFormatter = (str: string) => {
  return str.length > 280 ? str.substring(0, 280) + "..." : str;
};
