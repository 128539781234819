import { object, str } from "sydneyeval-shared";

export const UtteranceConversationId = object({
  conversationId: str,
  time: str,
});

export type UtteranceConversationId = ReturnType<
  typeof UtteranceConversationId
>;
