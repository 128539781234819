import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Field,
  Input,
  Option,
  Textarea,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import { productOptions } from "../../constants";
import { getIncident, setIncident } from "../../helpers/apiHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import type { IncidentList } from "../../models/Incident";
import { store } from "../../store/store";
import { useToast } from "../Wrappers/ToasterProvider";

type IncidentSettingDialogProps = {
  isOpen: boolean;
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  onComplete: () => void;
};

export const IncidentSettingDialog = (props: IncidentSettingDialogProps) => {
  const [title, setTitle] = React.useState<string>("");
  const [scope, setScope] = React.useState<string[]>([]);
  const [description, setDescription] = React.useState<string>("");
  const [incidentList, setIncidentList] = React.useState<IncidentList>([]);
  const toaster = useToast();

  useEffect(() => {
    setTitle("");
    setDescription("");
    setScope([]);
    getIncident().then((res) => {
      setIncidentList(res ?? []);
    });
  }, [props.isOpen]);

  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{`Add Incident`}</DialogTitle>
          <DialogContent>
            <Field
              required
              label={"Incident Title"}
              validationMessage={
                title.trim().length === 0
                  ? "Incident title cannot be empty."
                  : undefined
              }
            >
              <Input
                style={{ width: "100%" }}
                size="medium"
                value={title}
                onChange={(_, data) => {
                  setTitle(data.value);
                }}
              />
            </Field>

            <Field
              required
              label={"Impact Product"}
              validationMessage={
                scope.length === 0 ? "Impact scope cannot be empty." : undefined
              }
            >
              <Dropdown
                multiselect={true}
                placeholder="Select impact product"
                value={scope.join(",") ?? ""}
                selectedOptions={scope}
                onOptionSelect={(ev, data) => {
                  setScope(data.selectedOptions);
                }}
              >
                {productOptions
                  .filter((item) => item !== "All")
                  .map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
              </Dropdown>
            </Field>

            <Field
              required
              label={"Incident Description"}
              validationMessage={
                description.trim().length === 0
                  ? "Incident description cannot be empty."
                  : undefined
              }
            >
              <Textarea
                style={{ width: "100%" }}
                size="medium"
                value={description}
                onChange={(_, data) => {
                  setDescription(data.value);
                }}
              />
            </Field>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={
                title.trim().length === 0 || description.trim().length === 0
              }
              appearance="primary"
              onClick={() => {
                toaster.onToastStart("Adding incident...");
                props.onComplete();
                setIncident({
                  Incident: JSON.stringify([
                    ...incidentList,
                    {
                      title: `[${scope.join(",")}] ${title}`,
                      description: description,
                      product: scope.join(","),
                      createdAt: new Date().toUTCString(),
                      creator: store.account?.username ?? "",
                    },
                  ]),
                })
                  .then(() => {
                    toaster.onToastSuccess("Incident added successfully.");
                    telemetryHelper.logDevEvent("SetIncident", {
                      title,
                      description,
                    });
                    props.onSuccess?.();
                  })
                  .catch((error) => {
                    toaster.onToastFailure(error.message);
                    props.onFailure?.(error);
                  });
              }}
            >
              Set Incident
            </Button>
            <Button
              appearance="secondary"
              onClick={() => {
                props.onComplete();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
