import {
  Button,
  Menu,
  MenuItem,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  ArrowDownload16Filled,
  ArrowDownload16Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import React from "react";

import { compact } from "lodash";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { AlertDialog } from "../../../../../components/Dialog/AlertDialog";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { getHeronAccessToken } from "../../../../../helpers/accessTokenHelper";
import { getHeronJobOutputLink } from "../../../../../helpers/apiHelper";
import { getAppEnv } from "../../../../../helpers/appEnvHelper";
import { telemetryHelper } from "../../../../../helpers/telemetryHelper";
import { isHeronJobOutputLinkErrorResponse } from "../../../../../models/HeronJobOutputLinkResponse";

const DownloadIcon = bundleIcon(ArrowDownload16Filled, ArrowDownload16Regular);

interface DownloadOutputProps {
  readonly numberId: number;
  readonly jobId: string | undefined;
  readonly envId: number;
  readonly hasDownloadPermission?: boolean;
}

const outputFiles = [
  "output.zip",
  "scrape_output.zip",
  "llm_judge_ouput.zip",
  "metrics.zip",
  "sbs_input_data.zip",
  "llm_labeling_output.zip",
  "slice_and_dice_output.zip",
  "flattened_scrape_with_metrics_output.zip",
  "scrape_raw_data.zip",
  "llm_batch_output.zip",
] as const;

type OutputFileName = (typeof outputFiles)[number];

type DownloadLink = {
  link: string;
  filename: string;
};

type MenuOption = {
  text: string;
  link: DownloadLink[];
};

export const OutputFilesView = observer((props: DownloadOutputProps) => {
  const toaster = useToast();
  const [isGeneratingJobLinks, setIsGeneratingJobLinks] = React.useState(false);
  const [isJobLinksGenerated, setIsJobLinksGenerated] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);

  const [outputFileResults, setOutputFileResults] = React.useState<
    Partial<Record<OutputFileName, string>>
  >({});

  const onGetOutputFileLinks = async () => {
    if (isJobLinksGenerated) {
      return;
    }

    setIsGeneratingJobLinks(true);
    toaster.onToastStart("Generating output files download links...");

    const heronAccessToken = await getHeronAccessToken();

    return Promise.all(
      outputFiles.map(async (outputFile) => {
        const info = getAppEnv(props.envId).jobOutputInfo;

        const result = await getHeronJobOutputLink({
          WorkspaceId: info.workspaceId,
          SandboxId: info.sandBoxId,
          ContainerName: info.storageName,
          StoragePath: `/azureml/${props.jobId}/zippedfile/${outputFile}`,
          HeronAccessToken: heronAccessToken,
        });

        if (result === undefined) {
          return undefined;
        }

        if (isHeronJobOutputLinkErrorResponse(result)) {
          return undefined;
        }

        return { outputFile, value: result.value };
      }),
    )
      .then((results) => compact(results))
      .then((results) => {
        const resultsMap: Partial<Record<OutputFileName, string>> = {};
        results.forEach(({ outputFile, value }) => {
          resultsMap[outputFile] = value;
        });
        setOutputFileResults(resultsMap);
        toaster.onToastSuccess("Download links generated");
        setIsJobLinksGenerated(true);
      })
      .catch((error) =>
        toaster.onToastFailure(
          error.message,
          `Failed to generate download links`,
        ),
      )
      .finally(() => {
        setIsGeneratingJobLinks(false);
      });
  };

  const getMenuOptions = computed(() => {
    const createOption = (
      text: string,
      key: OutputFileName,
    ): MenuOption | undefined => {
      const link = outputFileResults[key];
      if (link !== undefined) {
        return { text, link: [{ link, filename: `${props.numberId}_${key}` }] };
      }
      return undefined;
    };

    const splitFileOptions = compact([
      createOption("Raw Scrape Responses", "scrape_raw_data.zip"),
      createOption("Scrape Data & Conversation", "scrape_output.zip"),
      createOption("Raw LLM Responses", "llm_batch_output.zip"),
      createOption("LLM Judge Output", "llm_judge_ouput.zip"),
      createOption("Metrics Data", "metrics.zip"),
      createOption("SBS Input Data", "sbs_input_data.zip"),
      createOption("LLM Labeling Output", "llm_labeling_output.zip"),
      createOption("Slice and Dice Output", "slice_and_dice_output.zip"),
      createOption(
        "Flattened Scrape with Metrics",
        "flattened_scrape_with_metrics_output.zip",
      ),
      createOption("Output", "output.zip"),
    ]);
    const downloadAllFilesOption =
      splitFileOptions.length !== 0
        ? {
            text: "All Files",
            link: splitFileOptions.map((option) => option.link[0]),
          }
        : undefined;

    return compact([downloadAllFilesOption, ...splitFileOptions]);
  });

  const renderMenuPopover = () => {
    if (props.hasDownloadPermission) {
      return (
        <MenuPopover>
          {isGeneratingJobLinks && (
            <MenuItem disabled>Generating download links...</MenuItem>
          )}
          {!isGeneratingJobLinks && getMenuOptions.get().length === 0 && (
            <MenuItem disabled>Missing Output Files</MenuItem>
          )}
          {!isGeneratingJobLinks && getMenuOptions.get().length !== 0 && (
            <>
              {getMenuOptions.get().map((option) => (
                <MenuItem
                  key={option.text}
                  onClick={() => {
                    option.link.forEach((link, index) => {
                      setTimeout(() => {
                        const customizedLink = `${
                          getAppEnv().apiEndpoint
                        }/customizedDownload?link=${encodeURIComponent(
                          link.link,
                        )}&filename=${encodeURIComponent(link.filename)}`;
                        window.open(customizedLink, "_blank");
                      }, 300 * index);
                    });
                    telemetryHelper.logUserActionEvent("DownloadFile", {
                      option: option.text,
                    });
                  }}
                >
                  {option.text}
                </MenuItem>
              ))}
            </>
          )}
        </MenuPopover>
      );
    }

    return <></>;
  };

  return (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button
            icon={<DownloadIcon />}
            onClick={() => {
              if (props.hasDownloadPermission) {
                onGetOutputFileLinks();
              } else {
                setShowAlertDialog(true);
              }
              telemetryHelper.logUserActionEvent("ClickDownloadFiles");
            }}
          >
            Download
          </Button>
        </MenuTrigger>
        {renderMenuPopover()}
      </Menu>

      {showAlertDialog && (
        <AlertDialog
          title="Restricted access"
          content="You don't have access to download the output. Please ask the job owner to share it offline if you need it."
          isOpen={showAlertDialog}
          onClose={() => {
            setShowAlertDialog(false);
          }}
        />
      )}
    </>
  );
});
