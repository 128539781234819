import type { CreateJobRouterRequestData } from "sydneyeval-shared";
import { TemplateType } from "sydneyeval-shared";
import {
  bingAzureStorageUrlRoot,
  folderPath_bingJobConfig,
  folderPath_CWCJobConfig,
} from "../../../constants/constants";
import { store } from "../../../store/store";
import { uploadFile } from "../actions/uploadFile";
import { generateFormattedDateString } from "../helpers/formatHelper";
import { jobShareStore } from "../store/jobShareStore";
import { jobStore } from "../store/jobStore";
import { getTemplateType } from "./getTemplateType";

const uploadConfigAndReturnJob = async (folder: string) => {
  const jsonString = JSON.stringify(jobStore.configuration, null, 2);
  const jsonFile = new File([jsonString], jobStore.jobName + ".json", {
    type: "text/plain",
  });
  const jobName = jobStore.jobName + "_" + generateFormattedDateString();
  const settingsFileName = `${folder}/${store.account?.localAccountId}/${jobName}.json`;
  const amlPath = bingAzureStorageUrlRoot + settingsFileName;
  const job = {
    JobName: jobName,
    CreatedBy: store.account?.name ?? "Unknown",
    ExperimentName: jobStore.selectedTemplate?.ExperimentName ?? "",
    JobTemplateId: jobStore.selectedTemplate?.Id ?? 0,
    Settings: JSON.stringify({
      config_file: {
        type: "uri_file",
        path: amlPath,
      },
    }),
  };

  await uploadFile(settingsFileName, jsonFile, "Bing", undefined, () => {
    throw new Error("Fail to upload settings to storage.");
  });

  return job;
};

export const generateCurrentJob =
  async (): Promise<CreateJobRouterRequestData> => {
    switch (getTemplateType.get()) {
      case TemplateType.BingV2:
        return await uploadConfigAndReturnJob(folderPath_bingJobConfig);
      case TemplateType.CWC:
        return await uploadConfigAndReturnJob(folderPath_CWCJobConfig);
      default:
        return {
          JobName: jobStore.jobName + "-" + generateFormattedDateString(),
          ExperimentName: jobStore.selectedTemplate?.ExperimentName ?? "",
          JobTemplateId: jobStore.selectedTemplate?.Id ?? 0,
          Settings: jobStore.configurationJsonString,
          DataSets: jobStore.dataSetsString,
          PermissionObjects: jobShareStore.permissionList.map(
            (item) => item.ObjectId,
          ),
        };
    }
  };
