import { Link, makeStyles, mergeClasses } from "@fluentui/react-components";
import { useMotion } from "@fluentui/react-motion-preview";
import { observer } from "mobx-react-lite";
import { getAppEnv } from "../../helpers/appEnvHelper";
import { isFeatureEnabled } from "../../selectors/features";

const useStyles = makeStyles({
  upgradeBannerContainer: {
    flexShrink: 0,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#4caf50",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    zIndex: 1000001,
    transitionDuration: "0.25s",
    transitionDelay: "0s",
    transitionProperty: "opacity, height",
    willChange: "opacity, height",
    opacity: 0,
  },
  upgradeContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  upgradeTitle: {
    width: "100%",
    textAlign: "center",
    padding: "12px 16px",
    fontSize: "large",
    color: "white",
  },
  visible: {
    opacity: 1,
  },
});

export const CosmicUpgradeBanner = observer(() => {
  const styles = useStyles();

  const motion = useMotion<HTMLDivElement>(
    isFeatureEnabled("show-upgrade-banner"),
  );

  const cosmicEndpoint = getAppEnv().cosmicEndpoint;
  const azureEndpoint = getAppEnv().azureEndpoint;

  return (
    <>
      {motion.canRender && (
        <div
          ref={motion.ref}
          className={mergeClasses(
            styles.upgradeBannerContainer,
            motion.active && styles.visible,
          )}
        >
          <div className={styles.upgradeContainer} key="CosmicUpgrade">
            <div className={styles.upgradeTitle}>
              SEVAL has successfully migrated to Cosmic Service. Please use{" "}
              <Link
                href={cosmicEndpoint}
                inline
                target="_blank"
                rel="noopener noreferrer"
              >
                Cosmic Service
              </Link>{" "}
              to access SEVAL. Should you encounter any issues, kindly report
              them. You also can revert to{" "}
              <Link
                href={azureEndpoint}
                inline
                target="_blank"
                rel="noopener noreferrer"
              >
                Azure Service
              </Link>{" "}
              if necessary until October 8th, 2024.
            </div>
          </div>
        </div>
      )}
    </>
  );
});
