import {
  Button,
  Combobox,
  Field,
  InfoLabel,
  Option,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import {
  BizChat_Official_Eval_Templates,
  BizChat_Official_Test_Tenant_Set_Paths,
} from "../../../../../constants";
import { checkOnlineQuerySetContent } from "../../../../../helpers/apiHelper";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { updateBizchatQuerySetValidationResult } from "../../../mutators/jobMutators";
import QuerysetWhitelist from "../../../resources/querysetWhitelist.json";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getInputFile } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { UploadingFileDialog } from "../../Dialog/UploadingFileDialog";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    listbox: {
      maxHeight: "300px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

interface JobSelectedFileViewProps {
  customQuerySets: string[];
  setCustomQuerySets: (querySets: string[]) => void;
}

export const JobSelectedFileView = observer(
  (props: JobSelectedFileViewProps) => {
    const { customQuerySets, setCustomQuerySets } = props;

    const toast = useToast();
    const [isCheckingHeader, setIsCheckingHeader] = useState(false);
    const [isTestSetFileDialogOpen, setIsTestSetFileDialogOpen] =
      useState(false);
    const [boundaryRef, setBoundaryRef] = useState<HTMLDivElement | null>(null);

    const styles = useStyles();

    const selectedTemplate = jobStore.selectedTemplate;
    if (selectedTemplate === undefined) {
      return <></>;
    }
    let querySets = customQuerySets.sort();
    if (
      BizChat_Official_Eval_Templates.includes(selectedTemplate.Name) &&
      jobStore.selectedEvaluationType == "FakeTenant"
    ) {
      querySets = [...BizChat_Official_Test_Tenant_Set_Paths, ...querySets];
    }
    const testFileName = getInputFile.get() ?? "";

    const error = getJobErrorByPath("configs_in_json.conversations.input_file");

    const getValidationState = () => {
      if (isCheckingHeader) {
        return "warning";
      }

      if (error === undefined) {
        return "success";
      }

      return "error";
    };

    const getValidationMessage = () => {
      if (isCheckingHeader) {
        return "Checking header...";
      }

      if (error === undefined) {
        return "QuerySet Header Validation Success.";
      }

      return error.message;
    };

    const handleQuerySetUpdated = (value: string) => {
      if (value.startsWith("data/") || QuerysetWhitelist.includes(value)) {
        setIsCheckingHeader(false);
        return;
      }

      setIsCheckingHeader(true);

      checkOnlineQuerySetContent({
        FileName: value,
      })
        .then(() => {
          updateBizchatQuerySetValidationResult(value, {
            state: "success",
            message: undefined,
          });
        })
        .catch((_) => {
          updateBizchatQuerySetValidationResult(value, {
            state: "error",
            message: "QuerySet Content Validation Failed: " + _.message,
          });
        })
        .finally(() => {
          setIsCheckingHeader(false);
        });
    };

    const onFileUploadStart = () => {
      toast.onToastStart("Uploading file...");
    };

    const onFileUploadSuccess = () => {
      toast.onToastSuccess("File uploaded successfully");
    };

    const onFileUploadFailure = (e: Error) => {
      toast.onToastFailure(`File upload failed with message: ${e.message}`);
    };

    useEffect(() => {
      if (testFileName) {
        handleQuerySetUpdated(testFileName);
      }
    }, [testFileName]);

    return (
      <div ref={setBoundaryRef} className={styles.block}>
        <InfoLabel
          className={styles.blockTitle}
          required
          info={
            "Specify the file path of input file from SEVAL query set storage"
          }
        >
          Test set
        </InfoLabel>
        <div>
          For flight review, please use the 1K set and personal account. Please
          follow this wiki (
          <a
            target="_blank"
            href="https://msasg.visualstudio.com/Cortana/_wiki/wikis/M365%20Chat.wiki/167953/Use-Personalized-Query-Set-Generator-in-SEVAL"
            rel="noreferrer"
          >
            link
          </a>
          ) to generate personalized query set in Query Set tab in SEVAL.
        </div>

        <Field
          validationState={getValidationState()}
          validationMessage={getValidationMessage()}
        >
          <div className={styles.rowWithSmallGap}>
            <Combobox
              positioning={{
                overflowBoundary: boundaryRef,
              }}
              aria-label="Specify the file path of input file from SEVAL query set storage"
              style={{ flex: 1 }}
              listbox={{ className: styles.listbox }}
              value={testFileName}
              selectedOptions={[testFileName]}
              onOptionSelect={(_, data) => {
                if (data.optionValue !== undefined) {
                  updatePropValueActionV2({
                    prop: "configs_in_json.conversations.input_file",
                    newData: data.optionValue,
                  });
                }
              }}
            >
              {querySets.map((query, index) => {
                return <Option key={index}>{query}</Option>;
              })}
            </Combobox>
            <Button
              appearance="primary"
              onClick={() => {
                setIsTestSetFileDialogOpen(true);
              }}
            >
              Upload File
            </Button>
          </div>
        </Field>
        <UploadingFileDialog
          type="Query"
          isOpen={isTestSetFileDialogOpen}
          onCancel={() => setIsTestSetFileDialogOpen(false)}
          onStart={onFileUploadStart}
          onSuccess={(fileName) => {
            updatePropValueActionV2({
              prop: "configs_in_json.conversations.input_file",
              newData: fileName,
            });
            setCustomQuerySets([...customQuerySets, fileName]);
            setIsTestSetFileDialogOpen(false);
            onFileUploadSuccess();
          }}
          onFailure={(e) => {
            setIsTestSetFileDialogOpen(false);
            onFileUploadFailure(e);
          }}
        />
      </div>
    );
  },
);
