import { Button, Card, Link, Spinner } from "@fluentui/react-components";
import React, { useEffect } from "react";
import { formatCreateTime } from "../../helpers/formatHelper";

import { ArrowRightRegular, Clock16Regular } from "@fluentui/react-icons";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { getExpNames, parseJsonStrOptional } from "sydneyeval-shared";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { RenderManifest } from "../../../../constants/renderManifest";
import {
  getJob,
  getJobOutput,
  getJobPerfMetric,
  getJobSBSExp,
  getLMChecklistAssertions,
  getLMChecklistDetails,
  getMetricsByJobId,
  getOPGRAIResult,
  getPrivacyFilterData,
} from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import { openSidePane } from "../../../../mutators/sidePaneMutators";
import { isFeatureEnabled } from "../../../../selectors/features";
import { calculateSbsExpLinks } from "../../helpers/JobSBSExpResultHelper";
import { hasDownloadPermission } from "../../helpers/downloadHelper";
import type { MetricsData } from "../../helpers/metricsHelper";
import { converPerfMetricsData } from "../../helpers/perfMetricsDataHelper";
import type { Job } from "../../models/Job";
import { JobSBSExpResult } from "../../models/JobSBSExpResult";
import {
  updateGeneralMetricsResponse,
  updateLMChecklistAssertionsResponse,
  updateLMChecklistDetailsResponse,
  updatePrivacyFilterResponse,
} from "../../mutators/jobDetailsMutators";
import {
  addLoadingMessage,
  removeLoadingMessage,
  updateBingJobOutputfiles,
  updateConciseTable,
  updateManifest,
  updatePerfMetricsData,
  updateResultJob,
  updateSBSLinks,
  updateSummaryTable,
  updateTemplateType,
} from "../../mutators/jobResultMutators";
import { disableDiagnosisView } from "../../selectors/jobDetailHelper";
import { quickCreateScheduleJob } from "../../selectors/scheduleJobSelector";
import { resultStore } from "../../store/resultStore";
import { JobStatusCell } from "../JobList/JobCell/JobStatusCell";
import { ResultsView } from "../ResultsComparison/ResultsView";
import { BingDownloadButton } from "../ResultsComparison/components/BingDownloadButton";
import { NoPermissionDialog } from "../ResultsComparison/components/NoPermissionDialog";
import { OutputFilesView } from "../ResultsComparison/components/OutputFilesView";
import { BingMonitorView } from "./components/BingMonitorView";
import { InforButtonView } from "./components/InforButtonView";
import { SBSLinksButton } from "./components/SBSLinksButton";
import { SettingPane } from "./components/SettingPane";
import { ShareButton } from "./components/ShareButton";
import { StudioURLButton } from "./components/StudioURLButton";
import { useDetailPageStyles } from "./sharedStyles";

export const JobDetailView = observer(() => {
  const { jobId } = useParams();
  const { templateType } = resultStore;

  const job = resultStore.resultJob;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const showDownloadButton = computed(
    () =>
      templateType !== "BingV2" &&
      templateType !== "BingGenerator" &&
      job?.ExperimentName !== "BizChat-Personalized-Query-Set-Generator-V2" &&
      templateType !== "CWC" &&
      (job?.Status === "Completed" ||
        job?.Status === "Running" ||
        job?.Status === "Failed"),
  );

  const shouldShowBingOrCWCDownloadButton = computed(
    () =>
      (templateType === "BingV2" || templateType === "CWC") &&
      job?.Status === "Completed",
  );

  const initMChatDetailData = (loadedJob: Job) => {
    if (loadedJob.JobId === undefined) {
      return;
    }

    // Start Non-Blocking API Calls
    const JobId = loadedJob.JobId;

    addLoadingMessage("Loading LM Checklist");
    // Incident Link: https://portal.microsofticm.com/imp/v3/incidents/incident/447761203/summary
    // Current solution is to catch the error and ignore it to make sure the page can be loaded
    // TODO: Improve the LMCheckList Performance and remove the catch
    perfWrapper(
      "LoadMChatMetricDiagnosisLMChecklist",
      Promise.all([
        getLMChecklistDetails({ JobId })
          .catch(() => undefined)
          .then((response) => {
            updateLMChecklistDetailsResponse(response);
          }),
        getLMChecklistAssertions({ JobId })
          .catch(() => undefined)
          .then((response) => {
            updateLMChecklistAssertionsResponse(response);
          }),
      ]),
    ).finally(() => {
      removeLoadingMessage("Loading LM Checklist");
    });

    addLoadingMessage("Loading Privacy Filter Query Set");
    perfWrapper(
      "LoadPrivacyFilterFilterdQuerySet",
      getPrivacyFilterData(JobId)
        .then((response) => {
          updatePrivacyFilterResponse(response);
        })
        .catch(() => {
          updatePrivacyFilterResponse([]);
        }),
    ).finally(() => {
      removeLoadingMessage("Loading Privacy Filter Query Set");
    });

    getSBSResult(loadedJob);
  };

  const initM365Data = (loadedJob: Job) => {
    if (loadedJob.JobId === undefined) {
      return;
    }

    const JobId = loadedJob.JobId;
    addLoadingMessage("Loading General Metrics");
    perfWrapper(
      "LoadMChatScorecardComparison",
      getMetricsByJobId({ JobId }).then((response) => {
        updateGeneralMetricsResponse(response);
      }),
    ).finally(() => {
      removeLoadingMessage("Loading General Metrics");
    });

    const shouldDisableMChatDiagnosis = disableDiagnosisView.get();

    if (!shouldDisableMChatDiagnosis) {
      initMChatDetailData(loadedJob);
    }
  };

  const initBingData = (loadedJob: Job) => {
    if (loadedJob?.Status === "Completed" && loadedJob?.StudioUrl) {
      const id = loadedJob?.StudioUrl.split("?")[0].split("runs/")[1];
      addLoadingMessage("Loading Bing Job Output Files");
      perfWrapper(
        "LoadBingScorecardComparison",
        getJobOutput(id)
          .then((result) => {
            const jobFiles = result.items.map((item, index) => {
              const splits = item.split("/");
              const fileName = splits[splits.length - 1];
              const folderName = splits[splits.length - 2];
              return {
                folderName: folderName,
                fileName: fileName,
                fullPath: item,
                sizeInBytes: result.sizeInBytes[index],
              };
            });
            updateBingJobOutputfiles(jobFiles);
          })
          .catch(() => {
            updateBingJobOutputfiles(undefined);
          }),
      ).finally(() => {
        removeLoadingMessage("Loading Bing Job Output Files");
      });
    }
  };

  const getRenderManifest = (loadedJob: Job) => {
    const templateName = loadedJob.ExperimentName;
    return RenderManifest.find(
      (manifest) => manifest.template === templateName,
    );
  };

  const getSBSResult = (loadedJob: Job) => {
    addLoadingMessage("Loading SBS Experiment");
    getJobSBSExp({ JobId: loadedJob.JobId ?? "" })
      .then((sbsResponse) => {
        if (sbsResponse.length > 0) {
          const result = parseJsonStrOptional(
            sbsResponse[0].experiments,
            JobSBSExpResult,
          );

          if (!result) {
            return;
          }
          const SBSExpResult = calculateSbsExpLinks(result);
          updateSBSLinks(SBSExpResult);
        }
      })
      .finally(() => {
        removeLoadingMessage("Loading SBS Experiment");
      });
  };

  const toast = useToast();

  const initPerfMetrics = () => {
    const expNames = getExpNames(resultStore.resultJob?.Settings ?? "");
    const jobID = resultStore.resultJob?.JobId;
    const SourceA = `eval__${expNames.control}_${jobID}`;
    const SourceB = `eval__${expNames.experiment}_${jobID}`;
    updatePerfMetricsData([]);
    toast.onToastStart("Loading Performance Metrics Data...");
    perfWrapper(
      "LoadPerfMetricsData",
      getJobPerfMetric({ SourceA, SourceB })
        .then((result) => {
          let metricResult: MetricsData[] = [];
          result.forEach((item) => {
            const oneResult = item.tables.find(
              (table) => table.name === "PrimaryResult",
            );
            const convertResult = converPerfMetricsData(
              item.metric,
              oneResult?.data ?? [],
            );
            metricResult = [...metricResult, ...convertResult];
          });
          updatePerfMetricsData(metricResult);
          toast.onToastSuccess("Performance Metrics Data Loaded!");
        })
        .catch(() => {
          toast.onToastFailure("Failed to Load Performance Metrics Data!");
        }),
    );
  };

  useEffect(() => {
    const jobIdNumber = Number(jobId);

    if (isNaN(jobIdNumber)) {
      setIsLoading(false);
      return;
    }

    perfWrapper(
      "LoadJobInfo",
      getJob({ jobId: jobIdNumber.toString() }).then((result) => {
        const loadedJob = result[0];
        if (loadedJob) {
          updateResultJob(loadedJob);
          updateTemplateType(loadedJob.JobTemplateType);
          setIsLoading(false);
          if (
            loadedJob.JobTemplateType === "BingV2" ||
            loadedJob.JobTemplateType === "CWC"
          ) {
            initBingData(loadedJob);
          } else {
            const renderManifest = getRenderManifest(loadedJob);
            if (!renderManifest) {
              initM365Data(loadedJob);

              const isCompletedMChatJob = () => {
                return (
                  loadedJob.JobTemplateType !== "BingV2" &&
                  loadedJob.JobTemplateType !== "CWC" &&
                  loadedJob.JobTemplateType != "OPG" &&
                  loadedJob.JobTemplateType != "BingGenerator" &&
                  loadedJob.Status === "Completed"
                );
              };
              if (isCompletedMChatJob()) {
                initPerfMetrics();
              }
            } else {
              updateManifest(renderManifest);
              getOPGRAIResult({
                JobId: loadedJob.JobId ?? "",
                Manifest: JSON.stringify(renderManifest),
              }).then((response) => {
                updateSummaryTable(response.summary);
                updateConciseTable(response.concise);
              });
            }
          }
        }
      }),
    );
  }, [jobId]);

  const styles = useDetailPageStyles();

  if (isLoading) {
    return <Spinner />;
  }

  const isBingScheduleJob =
    job?.Properties?.IsBingJob && job?.JobType === "Scheduled";

  const renderCards = () => {
    if (!job?.HasMetrics) {
      return <></>;
    }

    return <ResultsView />;
  };

  const supportQuickSchedule =
    job?.Properties?.IsOwner === true &&
    isFeatureEnabled("schedule-job-management") &&
    job?.Status === "Completed";

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{job?.JobName}</div>
        <div className={styles.operationButtons}>
          <InforButtonView />
          {shouldShowBingOrCWCDownloadButton.get() && <BingDownloadButton />}
          <ShareButton />
          {showDownloadButton.get() && (
            <OutputFilesView
              numberId={job?.ID ?? 0}
              jobId={job?.JobId ?? ""}
              envId={job?.EnvironmentId ?? 1}
              hasDownloadPermission={hasDownloadPermission()}
            />
          )}
          {supportQuickSchedule && (
            <Button
              onClick={() => {
                quickCreateScheduleJob(job);
              }}
              icon={<Clock16Regular />}
            >
              Set up regular run
            </Button>
          )}
        </div>
      </div>
      <Card className={styles.infoCard}>
        <div className={styles.infoGridLayout}>
          <div>
            <div className={styles.infoGrid}>
              <div className={styles.infoTitle}>Create At</div>
              <div>{formatCreateTime(job?.CreatedAt)}</div>
              <div className={styles.infoTitle}>Create By</div>
              <div>{job?.CreatedBy}</div>
            </div>
          </div>
          <div>
            <div className={styles.infoGrid}>
              <div className={styles.infoTitle}>Status</div>
              <div>
                <JobStatusCell job={job}></JobStatusCell>
              </div>
              {job?.StudioUrl && <StudioURLButton />}
              {isBingScheduleJob && <BingMonitorView />}
            </div>
          </div>
        </div>

        {resultStore.sbsLinks && resultStore.sbsLinks.length > 0 && (
          <SBSLinksButton />
        )}

        <div className={styles.infoTitle}>
          <Link
            className={styles.infoTitle}
            onClick={() => {
              openSidePane(() => <SettingPane />, "wide");
            }}
          >
            Show Config File <ArrowRightRegular />
          </Link>
        </div>
      </Card>
      {renderCards()}
      {resultStore.loadingQueue.length > 0 && <Spinner />}
      <NoPermissionDialog />
    </div>
  );
});
