import { computed } from "mobx";
import { isFeatureEnabled } from "../../../selectors/features";
import { jobStore } from "../store/jobStore";
import { getQuickFilterTemplates } from "./getQuickFilterTemplates";

export const getQuickFilterDefaultTemplate = computed(() => {
  if (!isFeatureEnabled("product-registration")) {
    const templates = getQuickFilterTemplates.get();
    switch (jobStore.quickTemplateTypeFilter) {
      case "All":
      case "M365 Chat":
        return (
          templates.find((template) =>
            template.Name.includes(
              "BizChat_Evaluation_Flight_Review_For_Avalon",
            ),
          ) ?? templates[0]
        );
      case "CWC":
        return (
          templates.find((template) =>
            template.Name.includes("CWC_multiturn"),
          ) ?? templates[0]
        );
      default:
        return templates[0];
    }
  } else {
    const templates = getQuickFilterTemplates.get();
    return templates[0];
  }
});
