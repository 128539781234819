import { constStr, object, optional, or, str } from "sydneyeval-shared";

export const JobTemplateFilterType = or([
  constStr("All"),
  constStr("M365 Chat"),
  constStr("Bing"),
  constStr("OPG"),
  constStr("CWC"),
]);

export type JobTemplateFilterType = ReturnType<typeof JobTemplateFilterType>;

export const LocalCache = object({
  jobCreationQuickFilter: optional(JobTemplateFilterType),
  productFilter: optional(str),
});
