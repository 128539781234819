import { Button, makeStyles } from "@fluentui/react-components";
import { Warning20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
  button: {
    backgroundColor: "rgba(221, 66, 66, 0.4)",
    "&:hover": {
      backgroundColor: "rgba(221, 66, 66, 0.5)",
    },
  },
  warningIcon: {
    marginRight: "2px",
  },
});

type LMChecklistCriticalFailureBannerProps = {
  handleBannerClick: () => void;
};

export const LMChecklistCriticalFailureBanner = observer(
  (props: LMChecklistCriticalFailureBannerProps) => {
    const styles = useStyles();
    return (
      <Button className={styles.button} onClick={props.handleBannerClick}>
        <Warning20Regular className={styles.warningIcon} />
        <span>
          Warning: This job has critical LM Checklist regressions. Click to
          view.
        </span>
      </Button>
    );
  },
);
