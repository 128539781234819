import { orchestrator } from "satcheljs";
import { type PreSubmitJobRouterRequestData } from "sydneyeval-shared";
import { preSubmitJob } from "../../../helpers/apiHelper";
import { executeJobPreVerification } from "../actions/jobActions";
import { JobPreValidationResponse } from "../models/JobPreValidationResponse";
import {
  updateIsFinishedVerifing,
  updateJobPrevalidationProgressbarValue,
  updateJobPrevalidationToastBody,
  updateJobPrevalidationToastTitle,
  updateJobValidationStepConfigInfo,
  updateJobValidationStepStatus,
} from "../mutators/jobPreValidationMutators";
import { getExpConfigs, getUserId } from "../selectors/getJobPropV2";
import { jobPreValidationStore } from "../store/jobPreValidationStore";

const validateJob = async (
  preSubmitJobParams: PreSubmitJobRouterRequestData,
) => {
  return await preSubmitJob(preSubmitJobParams);
};

const executeJobPreValidationStep = async (stepIndex: number) => {
  const expConfigs = getExpConfigs.get();
  const userid = getUserId.get();
  //skip check if user id is not in settings, which means it is a skip scraping job
  if (userid !== undefined && expConfigs !== undefined) {
    const config = expConfigs.find(
      (exp) =>
        exp.exp_name ===
        jobPreValidationStore.jobPreValidationSteps[stepIndex].stepName,
    );
    //skip check if config is not found
    if (config !== undefined) {
      const sydcomp_URL = "https://substrate.office.com/m365chat";
      const avalon_URL = "https://substrate.office.com/m365Copilot";
      const sydcomp_OptionSets = "enterprise_with_diagnostics";
      const avalon_OptionSets =
        "enterprise_flux_web,enterprise_flux_work,enterprise_toolbox_with_skdsstore,disable_action_confirmation";
      const jobTemplateNameLower =
        jobPreValidationStore.jobTemplateName?.toLowerCase();
      const isFlightReviewAvalon =
        jobTemplateNameLower?.includes("flight_review") &&
        jobTemplateNameLower?.includes("avalon");

      const getUrl = () => {
        if (config.sydney?.url) {
          return config.sydney.url;
        }
        if (isFlightReviewAvalon) {
          return avalon_URL;
        }
        return sydcomp_URL;
      };

      const getOptionSets = () => {
        // check url here to see if it is a flight review job
        if (config.sydney?.url) {
          // return option sets from config if it is not a flight review job
          return config.sydney?.option_sets;
        }
        if (isFlightReviewAvalon) {
          return avalon_OptionSets;
        }
        return sydcomp_OptionSets;
      };

      const url = getUrl();
      const optionSets = getOptionSets();

      const preSubmitJobParams: PreSubmitJobRouterRequestData = {
        experimentId: config.exp_name ?? "",
        variants: config.sydney?.variants,
        host: url,
        optionSets: optionSets,
        options: config.sydney?.options,
        locationInfo: config.sydney?.locationInfo,
        userid: userid ?? "",
        market: config.sydney?.market,
      };
      updateJobValidationStepConfigInfo(stepIndex, config);
      await Promise.resolve(validateJob(preSubmitJobParams))
        .then((result) => {
          if (typeof result === "object") {
            const JobPreValidationResponseParser = JobPreValidationResponse(
              result,
              "JobPreValidationResponseParser",
            );

            updateJobValidationStepStatus(
              stepIndex,
              JobPreValidationResponseParser.verificationStatusCode === 200
                ? "scrapping passed"
                : "scrapping failed",
              JobPreValidationResponseParser,
            );
          }
        })
        .catch((error) => {
          updateJobValidationStepStatus(
            stepIndex,
            "scrapping failed",
            error instanceof Error ? error.message : "Unknown error",
          );
        });
      return;
    }
  }
  updateJobValidationStepStatus(
    stepIndex,
    "skipped",
    `${jobPreValidationStore.jobPreValidationSteps[stepIndex].stepName} cannot be found in configuration file.`,
  );
};

orchestrator(executeJobPreVerification, () => {
  const totalSteps = jobPreValidationStore.jobPreValidationSteps.length;
  let completedSteps = 0;
  updateJobPrevalidationToastTitle(
    `Job validation in progress, it will spend about 30 seconds `,
  );
  updateJobPrevalidationToastBody(
    `${completedSteps} of ${jobPreValidationStore.jobPreValidationSteps.length} completed`,
  );
  updateJobPrevalidationProgressbarValue(0);

  const updateProgress = () => {
    completedSteps += 1;
    updateJobPrevalidationToastBody(
      `${completedSteps} of ${jobPreValidationStore.jobPreValidationSteps.length} completed`,
    );
    const progress = (completedSteps / totalSteps) * 100;
    updateJobPrevalidationProgressbarValue(progress);
  };

  const promises = jobPreValidationStore.jobPreValidationSteps.map((_, index) =>
    executeJobPreValidationStep(index)
      .then(updateProgress)
      .catch((error) => {
        updateJobValidationStepStatus(
          index,
          "scrapping failed",
          error instanceof Error ? error.message : "Unknown error",
        );
      }),
  );

  Promise.all(promises).then(() => {
    updateIsFinishedVerifing(true);
  });
});
