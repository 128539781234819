import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Bug24Filled, Bug24Regular, bundleIcon } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { telemetryHelper } from "../../helpers/telemetryHelper";
const useStyle = makeStyles({
  icon: {
    lineHeight: "20px",
    color: "white",
    height: "20px",
    width: "24px",
  },
  button: {
    marginRight: "20px",
    color: "white",
    fontSize: "12px",
    fontWeight: "400",
    padding: "0px",
  },
  seperator: {
    marginRight: "20px",
    width: "1px",
    height: "11px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
});

const FeedbackIcon = bundleIcon(Bug24Filled, Bug24Regular);
const reportIssueLink1 =
  "https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=J3V151";
const reportIssueLink2 =
  "https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=v161Sf";

export const AppBarReportIssue = observer(() => {
  const styles = useStyle();

  return (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button
            aria-label={"Report Incident"}
            className={styles.button}
            appearance="transparent"
            icon={<FeedbackIcon className={styles.icon} />}
            onClick={() => {
              telemetryHelper.logUserActionEvent("ClickReportIncident");
            }}
          >
            Report Incident
          </Button>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem
              onClick={() => {
                window.open(reportIssueLink1, "_blank");
                telemetryHelper.logUserActionEvent("ClickReportBingIncident");
              }}
            >
              UI&O365 Incident
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(reportIssueLink2, "_blank");
                telemetryHelper.logUserActionEvent(
                  "ClickReportUIAndO365Incident",
                );
              }}
            >
              Bing Incident
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
      <div></div>
      <div className={styles.seperator}></div>
    </>
  );
});
