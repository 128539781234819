import type { SinglePageFeatureContribution } from "../../contribution/FeatureContribution";
import { getAppEnv } from "../../helpers/appEnvHelper";
import { isFeatureEnabled } from "../../selectors/features";
import { HumanCorrelationStudyEntry } from "./components/MainEntry";

export const humanCorrelationStudyContribution: SinglePageFeatureContribution =
  {
    type: "single-page",
    name: "Human Correlation Study",
    isEnabled: () =>
      getAppEnv().type !== "PROD" &&
      isFeatureEnabled("human-correlation-study"),
    defaultPath: "/llmjudge",
    pathRegistry: {
      "/llmjudge/*": {
        render: () => <HumanCorrelationStudyEntry />,
      },
    },
  };
