import {
  Button,
  Combobox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
  tokens,
} from "@fluentui/react-components";
import { Dismiss12Regular, DocumentEdit24Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { getMatchingOptions } from "../../../helpers/queryManagementHelper";
import { useQueryManagementStyles } from "../styles/QueryManagementStyles";
import type {
  ComboboxField,
  ComboboxOptions,
  FormValuesShared,
  SelectOptions,
} from "../types/Display";
import type { Query } from "../types/Query";
import { QueryManagementSharedDialogFields } from "./QueryManagementSharedDialogFields";

interface BulkEditFormValues extends FormValuesShared {
  addSets: string[];
  removeSets: string[];
}

type SetAction = "addSets" | "removeSets";

type QueryManagementBulkEditDialogProps = {
  isOpen: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  queries: Query[];
  checkedQueries: Set<Query>;
  setQueries: (value: React.SetStateAction<Query[]>) => void;
  dispatch: (
    value:
      | {
          type: "add" | "delete";
          query: Query;
        }
      | {
          type: "empty";
        },
  ) => void;
  selectOptions: SelectOptions;
};

export const QueryManagementBulkEditDialog = observer(
  (props: QueryManagementBulkEditDialogProps) => {
    const {
      isOpen,
      setOpen,
      queries,
      checkedQueries,
      setQueries,
      dispatch,
      selectOptions,
    } = props;
    const styles = useQueryManagementStyles();

    const initialize = {
      formValues: {
        segmentOne: "",
        segmentTwo: "",
        environment: "",
        term: "",
        queryOwner: "",
        freOrSparklePrompt: "",
        groundingDataSource: "",
        dataSourceInfo: "",
        addSets: [],
        removeSets: [],
      },
      comboboxOptions: {
        segmentOne: [],
        segmentTwo: [],
        environment: [],
        term: [],
        freOrSparklePrompt: [],
        groundingDataSource: [],
      },
    };

    const [formValues, setFormValues] = useState<BulkEditFormValues>(
      initialize.formValues,
    );

    const [comboboxOptions, setFormSelectedOptions] = useState<ComboboxOptions>(
      initialize.comboboxOptions,
    );

    const [addSetsFieldValue, setAddSetsFieldValue] = useState("");
    const [addSets, setAddSets] = useState<string[]>([]);

    const [removeSetsFieldValue, setRemoveSetsFieldValue] = useState("");
    const [removeSets, setRemoveSets] = useState<string[]>([]);

    const getAddableSets = () => {
      return selectOptions.sets.filter((set) => !removeSets.includes(set));
    };

    const getRemovableSets = () => {
      const allSets = new Set<string>();

      checkedQueries.forEach((query) => {
        query.sets?.forEach((set) => {
          allSets.add(set);
        });
      });

      return Array.from(allSets).filter((set) => !addSets.includes(set));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const field = event.target.id;

      const textOnlyFields = ["query", "queryOwner", "dataSourceInfo"];

      if (!textOnlyFields.includes(field)) {
        if (value.length === 0) {
          setFormSelectedOptions({
            ...comboboxOptions,
            [field]: [],
          });
        }
      }

      setFormValues({
        ...formValues,
        [field]: value,
      });
    };

    const handleOptionSelect = (
      field: ComboboxField,
      option: string | undefined,
    ) => {
      if (option === undefined) {
        return;
      }

      if (option === "") {
        option = formValues[field];
      }

      setFormValues({
        ...formValues,
        [field]: option,
      });

      setFormSelectedOptions({
        ...comboboxOptions,
        [field]: [option],
      });
    };

    const handleSetSelect = (set: string | undefined, action: SetAction) => {
      if (set === undefined) {
        return;
      }

      if (set === "") {
        set = action === "addSets" ? addSetsFieldValue : removeSetsFieldValue;
      }

      let newSets: string[];
      const currentSets =
        action === "addSets" ? formValues.addSets : formValues.removeSets;

      if (currentSets.includes(set)) {
        newSets = currentSets.filter((value) => value !== set);
      } else {
        if (currentSets === undefined) {
          newSets = [set];
        } else {
          newSets = [...currentSets, set];
        }
      }

      setFormValues({
        ...formValues,
        [action]: newSets,
      });

      if (action === "addSets") {
        setAddSets(newSets);
      } else {
        setRemoveSets(newSets);
      }
    };

    const onTagClick = (option: string, action: SetAction) => {
      const newSets = formValues[action].filter((set) => set !== option);
      setFormValues({
        ...formValues,
        [action]: newSets,
      });

      if (action === "addSets") {
        setAddSets(newSets);
      } else {
        setRemoveSets(newSets);
      }
    };

    const handleSetsChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      action: SetAction,
    ) => {
      const value = event.target.value;
      if (action === "addSets") {
        setAddSetsFieldValue(value);
      } else {
        setRemoveSetsFieldValue(value);
      }
    };

    const getNewSets = (sets?: string[]) => {
      if (sets === undefined) {
        return sets;
      }

      const newSets = sets.filter((set) => !removeSets.includes(set));
      return [...new Set([...newSets, ...addSets])];
    };

    const handleSubmit = () => {
      const updatedQueries = queries.map((query) => {
        if (checkedQueries.has(query)) {
          return {
            ...query,
            segmentOne: formValues.segmentOne || query.segmentOne,
            segmentTwo: formValues.segmentTwo || query.segmentTwo,
            queryOwner: formValues.queryOwner || query.queryOwner,
            environment: formValues.environment || query.environment,
            term: formValues.term || query.term,
            freOrSparklePrompt:
              formValues.freOrSparklePrompt || query.freOrSparklePrompt,
            groundingDataSource:
              formValues.groundingDataSource || query.groundingDataSource,
            dataSourceInfo: formValues.dataSourceInfo || query.dataSourceInfo,
            sets: getNewSets(query.sets),
          };
        } else {
          return query;
        }
      });

      setQueries(updatedQueries);
      dispatch({ type: "empty" });
      setOpen(false);
    };

    const resetForm = () => {
      setFormValues(initialize.formValues);
      setFormSelectedOptions(initialize.comboboxOptions);
      setAddSets([]);
      setRemoveSets([]);
      setAddSetsFieldValue("");
      setRemoveSetsFieldValue("");
    };

    const emptyValues = () => {
      return Object.values(formValues).every((value) => value.length === 0);
    };

    const renderSetsComponent = (
      id: SetAction,
      label: string,
      value: string,
      selected: string[],
      options: string[],
      message?: string,
    ) => (
      <div>
        <Label className={styles.formLabel} htmlFor={id}>
          {label}
        </Label>
        <Combobox
          freeform
          multiselect
          id={id}
          data-testid={id}
          placeholder="Select multiple (or type to create)"
          aria-label={label}
          positioning="below-start"
          value={value}
          selectedOptions={selected}
          onChange={(e) => handleSetsChange(e, id)}
          onOptionSelect={(_, d) => handleSetSelect(d.optionValue, id)}
        >
          {getMatchingOptions(value, options, message)}
        </Combobox>
        {selected.length ? (
          <ul className={styles.tagsList}>
            {selected.map((set) => (
              <li key={set}>
                <Button
                  size="small"
                  shape="circular"
                  appearance="primary"
                  style={{
                    backgroundColor:
                      id === "addSets"
                        ? undefined
                        : tokens.colorPaletteRedForeground1,
                  }}
                  icon={<Dismiss12Regular />}
                  iconPosition="after"
                  onClick={() => onTagClick(set, id)}
                >
                  {set}
                </Button>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );

    return (
      <Dialog
        open={isOpen}
        onOpenChange={(_, { open }) => {
          setOpen(open);
          if (!open) {
            resetForm();
          }
        }}
      >
        <DialogTrigger>
          <Button
            className={styles.queryButtons}
            icon={<DocumentEdit24Regular />}
            data-testid="bulk-edit"
          >
            Bulk Edit
          </Button>
        </DialogTrigger>
        <DialogSurface className={styles.queryDialog}>
          <form
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <DialogBody>
              <DialogTitle>
                Bulk Edit{" "}
                <Label>
                  {checkedQueries.size}{" "}
                  {checkedQueries.size > 1 ? "queries" : "query"}
                </Label>
              </DialogTitle>
              <DialogContent className={styles.form}>
                <QueryManagementSharedDialogFields
                  selectOptions={selectOptions}
                  handleOptionSelect={handleOptionSelect}
                  handleChange={handleChange}
                  formValues={formValues}
                  comboboxOptions={comboboxOptions}
                  bulkEdit={true}
                />
                <div className={styles.formGroup}>
                  {renderSetsComponent(
                    "addSets",
                    "Add Sets",
                    addSetsFieldValue,
                    addSets,
                    getAddableSets(),
                    `Create "${addSetsFieldValue}"`,
                  )}
                  {renderSetsComponent(
                    "removeSets",
                    "Remove Sets",
                    removeSetsFieldValue,
                    removeSets,
                    getRemovableSets(),
                  )}
                </div>
              </DialogContent>
              <DialogActions className={styles.dialogActions}>
                <DialogTrigger>
                  <Button appearance="secondary">Close</Button>
                </DialogTrigger>
                <Button
                  appearance="primary"
                  type="submit"
                  disabled={emptyValues()}
                >
                  Save
                </Button>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
    );
  },
);
