"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJobSupportShare = exports.isJobUsingTestTenant = void 0;
const JobConfiguration_1 = require("../models/JobConfiguration");
const parseHelper_1 = require("./parseHelper");
const isJobUsingTestTenant = (jobConfig) => {
    // Parse settings to get user_id value
    const userId = (settings) => {
        const settingObject = (0, parseHelper_1.parseJsonStrOptional)(settings, JobConfiguration_1.JobConfiguration);
        return settingObject?.configs_in_json?.conversations?.user_id;
    };
    const userIdInConfig = userId(jobConfig);
    const isTestTenant = userIdInConfig
        ? !userIdInConfig?.endsWith("@microsoft.com")
        : false;
    return isTestTenant;
};
exports.isJobUsingTestTenant = isJobUsingTestTenant;
const isJobSupportShare = (jobConfig, JobTemplateType, JobType) => {
    const IsBingJob = JobTemplateType === "BingV2" || JobTemplateType === "CWC";
    const IsScheduledJob = JobType === "Scheduled";
    const IsOPGPRValidationJob = JobTemplateType === "OPG";
    const IsRAIJob = JobTemplateType === "RAI";
    // Bing job, sheduled job and OPG job, RAI job do not support share
    if (IsBingJob || IsScheduledJob || IsOPGPRValidationJob || IsRAIJob) {
        return false;
    }
    // Test tenant job do not support share
    if ((0, exports.isJobUsingTestTenant)(jobConfig)) {
        return false;
    }
    return true;
};
exports.isJobSupportShare = isJobSupportShare;
