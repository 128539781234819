"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Product = exports.ProductTemplate = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.ProductTemplate = (0, typeHelper_1.object)({
    id: typeHelper_1.str,
    displayName: typeHelper_1.str,
    pipelineExpName: typeHelper_1.str,
    settingsOverride: (0, typeHelper_1.optional)(typeHelper_1.str),
    createdTime: typeHelper_1.str,
    creatorId: typeHelper_1.str,
    lastUpdatedTime: typeHelper_1.str,
    isActive: typeHelper_1.bool,
});
exports.Product = (0, typeHelper_1.object)({
    id: typeHelper_1.str,
    displayName: typeHelper_1.str,
    templates: (0, typeHelper_1.array)(exports.ProductTemplate),
    creatorId: typeHelper_1.str,
    adminIds: (0, typeHelper_1.array)(typeHelper_1.str),
    status: (0, typeHelper_1.or)([(0, typeHelper_1.constStr)("draft"), (0, typeHelper_1.constStr)("published")]),
    publishedTime: (0, typeHelper_1.optional)(typeHelper_1.str),
    lastUpdatedTime: typeHelper_1.str,
});
