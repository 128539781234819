import * as React from "react";

import { ProgressBar, Text } from "@fluentui/react-components";
import {
  getAzureMLFileContent,
  getJobTemplates,
  getJobs,
} from "../../../../helpers/apiHelper";

import { observer } from "mobx-react-lite";
import {
  JobSettingsDialog,
  type TabValue,
} from "../../../../components/Dialog/JobSettingsDialog";
import { getRandomUUID } from "../../../../helpers/getRandomUUID";
import {
  perfWrapper,
  telemetryHelper,
} from "../../../../helpers/telemetryHelper";
import type { Job } from "../../models/Job";
import type { JobTemplate } from "../../models/JobTemplate";
import { GeneralTable } from "./GeneralTable";
import { JobActionsCell } from "./JobCell/JobActionsCell";
import { JobCreateDateCell } from "./JobCell/JobCreateDateCell";
import { JobCreatorCell } from "./JobCell/JobCreatorCell";
import { JobMetricsCell } from "./JobCell/JobMetricsCell";
import { JobNameCell } from "./JobCell/JobNameCell";
import { JobStatusCell } from "./JobCell/JobStatusCell";

interface JobTableProps {
  selectedStatus: string;
  jobNameKeyword?: string;
  creator?: string;
  filter?: string;
  product?: string;
}

export const JobsTable = observer((props: JobTableProps) => {
  const [isLoadingInitial, setIsLoadingInitial] = React.useState<boolean>(true);
  const [jobTemplates, setJobTemplates] = React.useState<JobTemplate[]>([]);
  const [jobs, setJobs] = React.useState<Job[]>([]);
  const [isFetchingNext, setIsFetchingNext] = React.useState<boolean>(false);
  const [canFetchNext, setCanFetchNext] = React.useState<boolean>(true);
  const targetRequestIdRef = React.useRef<string>("");

  const productFilter = () => {
    switch (props.product) {
      case "Bing":
        return "BingV2";
      case "CWC":
        return "CWC";
      case "OPG":
        return "OPG";
      case "M365 Chat":
        return Array.from(
          new Set(
            jobTemplates
              .map((item) => item.Type)
              .filter(
                (item) =>
                  item != "BingV2" &&
                  item != "CWC" &&
                  item != "OPG" &&
                  item != "BingGenerator",
              ),
          ),
        ).join(",");
      default:
        return undefined;
    }
  };

  const fetchNext = React.useCallback(() => {
    if (isFetchingNext || !canFetchNext) {
      return;
    }

    telemetryHelper.logUserActionEvent("FetchNextJob");
    setIsFetchingNext(true);

    const startedId = jobs[jobs.length - 1]?.ID;
    if (startedId === undefined) {
      return;
    }

    perfWrapper(
      "LoadNextJobList",
      getJobs({
        StartedId: startedId - 1,
        JobStatus: props.selectedStatus === "All" ? "" : props.selectedStatus,
        Keyword: props.jobNameKeyword,
        Creator: props.creator,
        Filter: props.filter,
        Product: productFilter(),
      })
        .then((fetchedJobs) => {
          jobs.push(...fetchedJobs);
          setJobs(jobs);

          if (fetchedJobs.length === 0) {
            setCanFetchNext(false);
            return;
          }
        })
        .finally(() => {
          setIsFetchingNext(false);
        }),
    );
  }, [isFetchingNext, canFetchNext, jobs.length]);

  const refreshList = () => {
    telemetryHelper.logUserActionEvent("SearchJob");

    setIsLoadingInitial(true);
    setIsFetchingNext(false);
    setCanFetchNext(true);
    setJobs([]);

    const requestId = getRandomUUID();
    targetRequestIdRef.current = requestId;

    perfWrapper(
      "LoadJobList",
      getJobs({
        JobStatus: props.selectedStatus === "All" ? "" : props.selectedStatus,
        Keyword: props.jobNameKeyword,
        Creator: props.creator,
        Filter: props.filter,
        Product: productFilter(),
      }).then((initialJobs) => {
        if (requestId !== targetRequestIdRef.current) {
          return;
        }
        setJobs(initialJobs);
        setIsLoadingInitial(false);
      }),
    );
  };

  React.useEffect(() => {
    getJobTemplates().then((templates) => {
      setJobTemplates(templates);
    });
  }, []);

  React.useEffect(() => {
    refreshList();
  }, [
    props.jobNameKeyword,
    props.selectedStatus,
    props.creator,
    props.filter,
    props.product,
  ]);

  // Dialog
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [settings, setSettings] = React.useState<string | undefined>(undefined);
  const [dataSets, setDataSets] = React.useState<string | undefined>(undefined);
  const [jobName, setJobName] = React.useState<string>("");
  const [jobId, setJobId] = React.useState<number | undefined>(undefined);
  const [lastSelectedTab, setLastSelectedTab] =
    React.useState<TabValue>("settings");
  const [jobLastSelectedTabMap, setJobLastSelectedTabMap] = React.useState<
    Map<number, TabValue>
  >(new Map<number, TabValue>());

  const onSettingPageClose = (selectedTab: TabValue) => {
    setIsDialogOpen(false);
    if (jobId) {
      jobLastSelectedTabMap.set(jobId, selectedTab);
      setJobLastSelectedTabMap(jobLastSelectedTabMap);
    }
  };

  const onShowJobSettings = (job: Job) => {
    setJobId(job.ID);
    setJobName(job.JobName ?? "");
    setSettings(job.Settings);
    setDataSets(job.DataSets);
    setIsDialogOpen(true);
    setLastSelectedTab(jobLastSelectedTabMap.get(job.ID) ?? "settings");
    const relatedTemplate = jobTemplates.find(
      (template) => template.Name === job.ExperimentName,
    );
    if (
      (relatedTemplate?.Type === "BingV2" || relatedTemplate?.Type === "CWC") &&
      job.Settings
    ) {
      const settingObj = JSON.parse(job.Settings);
      if (settingObj && settingObj.config_file && settingObj.config_file.path) {
        // Fetching json from storage
        const path = settingObj.config_file.path;
        getAzureMLFileContent(path)
          .then((json) => {
            try {
              const jsonObject = JSON.parse(json);
              const jsonStr = JSON.stringify(jsonObject, null, 2);
              setSettings(jsonStr);
            } catch (e) {
              setSettings(json);
            }
          })
          .catch(() => {
            setSettings(job.Settings);
          });
      }
    }
  };

  const renderList = () => (
    <GeneralTable
      loadMore={fetchNext}
      hasMore={canFetchNext}
      rows={jobs}
      columns={[
        {
          title: "JobName",
          render: (job) => <JobNameCell job={job} />,
          weight: 3,
          minWidth: "400px",
        },
        {
          title: "",
          render: (job) => (
            <JobActionsCell
              item={job}
              jobTemplates={jobTemplates}
              onJobSettingsClick={() => {
                onShowJobSettings(job);
              }}
              onRefreshJobList={refreshList}
            />
          ),
          weight: 0,
          minWidth: "50px",
        },
        {
          title: "CreatedBy",
          render: (job) => <JobCreatorCell job={job} />,
          weight: 1,
          minWidth: "150px",
        },
        {
          title: "Date",
          render: (job) => <JobCreateDateCell job={job} />,
          weight: 1,
          minWidth: "250px",
        },
        {
          title: "Status",
          render: (job) => <JobStatusCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
        {
          title: "Metrics",
          render: (job) => <JobMetricsCell job={job} />,
          weight: 0,
          minWidth: "150px",
        },
      ]}
    />
  );

  const renderEmptyTable = () => (
    <div>
      <Text>No jobs matching found</Text>
    </div>
  );

  return (
    <>
      {isLoadingInitial && <ProgressBar />}
      {!isLoadingInitial &&
        (jobs.length > 0 ? renderList() : renderEmptyTable())}
      {isDialogOpen && (
        <JobSettingsDialog
          isOpen={isDialogOpen}
          jobName={jobName}
          settings={settings}
          dataSets={dataSets}
          lastSelectedTab={lastSelectedTab}
          onClose={(selectedTab) => onSettingPageClose(selectedTab)}
        />
      )}
    </>
  );
});
