import { compact } from "lodash";
import { computed } from "mobx";
import { TemplateType } from "sydneyeval-shared";
import { jobStore } from "../../store/jobStore";
import { getValue } from "../getJobPropV2";
import { getTemplateType } from "../getTemplateType";
import { convertPathToKey } from "./getJobCreationError";
import { checkBingExpOptionSet } from "./pathCheckers/checkBingExpOptionSet";
import {
  checkBingCluster,
  checkBingScenario,
} from "./pathCheckers/checkBingInfo";
import { checkBingJsonFields } from "./pathCheckers/checkBingJsonFields";
import { checkBingTestSetOptions } from "./pathCheckers/checkBingTestSetOptions";
import { checkBingUserId } from "./pathCheckers/checkBingUserId";
import { checkBizChatQuerySetHeader } from "./pathCheckers/checkBizChatFileHeader";
import { checkBizChatFileName } from "./pathCheckers/checkBizChatFileName";
import { checkBlockedPath } from "./pathCheckers/checkBlockedPath";
import { checkEvalTimeOverride } from "./pathCheckers/checkEvalTimeOverride";
import { checkExpConfigs } from "./pathCheckers/checkExpConfigs";
import {
  checkExpName,
  checkExpNameNotEmpty,
} from "./pathCheckers/checkExpName";
import { checkInputFolder } from "./pathCheckers/checkInputFolder";
import { checkNotEmpty } from "./pathCheckers/checkNotEmpty";
import { checkSydneyClientSecret } from "./pathCheckers/checkSydneyClientSecret";
import { checkUrl } from "./pathCheckers/checkUrl";
import { checkVariant } from "./pathCheckers/checkVariant";

const getSydneyClientSecretErrors = () => {
  return (["configs_in_json.sydney_client_secret"] as const).map((path) =>
    checkSydneyClientSecret(getValue(path), convertPathToKey(path)),
  );
};

const getExpConfigsErrors = () => {
  return (["configs_in_json.conversations.exp_configs"] as const).map((path) =>
    checkExpConfigs(getValue(path), convertPathToKey(path)),
  );
};

const getExpNameErrors = () => {
  return (
    [
      "configs_in_json.conversations.exp_configs[0].exp_name",
      "configs_in_json.conversations.exp_configs[1].exp_name",
    ] as const
  ).map((path) => checkExpName(getValue(path), convertPathToKey(path)));
};

const getVariantErrors = () => {
  return (
    [
      "configs_in_json.conversations.exp_configs[0].sydney.variants",
      "configs_in_json.conversations.exp_configs[1].sydney.variants",
    ] as const
  ).map((path) => checkVariant(getValue(path), convertPathToKey(path)));
};

const getUrlErrors = () => {
  return (
    [
      "configs_in_json.conversations.exp_configs[0].sydney.url",
      "configs_in_json.conversations.exp_configs[1].sydney.url",
    ] as const
  ).map((path) => checkUrl(getValue(path), convertPathToKey(path)));
};

const getBizChatFileNameErrors = () => {
  return (["configs_in_json.conversations.input_file"] as const).map((path) =>
    checkBizChatFileName(getValue(path), convertPathToKey(path)),
  );
};

const getBizChatQuerySetHeaderErrors = () => {
  return (["configs_in_json.conversations.input_file"] as const).map((path) =>
    checkBizChatQuerySetHeader(
      jobStore.bizchatQuerySetValidationResult.get(getValue(path) ?? ""),
      convertPathToKey(path),
    ),
  );
};

const getEvalTimeOverrideErrors = () => {
  return (
    [
      "configs_in_json.conversations.eval_time_override",
      "configs_in_json.conversations.exp_configs[0].sydney.url",
      "configs_in_json.conversations.exp_configs[1].sydney.url",
      "configs_in_json.conversations.exp_configs[0].sydney.variants",
      "configs_in_json.conversations.exp_configs[1].sydney.variants",
    ] as const
  ).map((path) =>
    checkEvalTimeOverride(getValue(path), convertPathToKey(path)),
  );
};

const getInputFolderErrors = () => {
  return (["configs_in_json.conversations.input_folder"] as const).map((path) =>
    checkInputFolder(getValue(path), convertPathToKey(path)),
  );
};

const getBlockedPathErrors = () => {
  return (
    [
      "configs_in_json.conversations.exp_configs[0].sydney.verbosity",
      "configs_in_json.conversations.exp_configs[1].sydney.verbosity",
    ] as const
  ).map((path) => checkBlockedPath(getValue(path), convertPathToKey(path)));
};

export const getConfigPathErrorsByRule = computed(() => {
  switch (getTemplateType.get()) {
    case TemplateType.Normal:
      return compact(
        [
          getBlockedPathErrors(),
          getSydneyClientSecretErrors(),
          getExpConfigsErrors(),
          getExpNameErrors(),
          getUrlErrors(),
          getVariantErrors(),
          getBizChatFileNameErrors(),
          getBizChatQuerySetHeaderErrors(),
          getEvalTimeOverrideErrors(),
        ].reduce((acc, val) => acc.concat(val), []),
      );

    case TemplateType.BizChatFlightReview:
      return compact(
        [
          getBlockedPathErrors(),
          getSydneyClientSecretErrors(),
          getExpConfigsErrors(),
          getVariantErrors(),
          getBizChatFileNameErrors(),
          getBizChatQuerySetHeaderErrors(),
        ].reduce((acc, val) => acc.concat(val), []),
      );

    case TemplateType.BingV2:
    case TemplateType.CWC:
      return compact(
        Array.from(Array((getValue("exp_configs") ?? []).length).keys())
          .map((index) => [
            checkBingJsonFields(
              jobStore.inputCache.get(`exp_configs[${index}].extra_fields`),
              convertPathToKey(`exp_configs[${index}].extra_fields`),
            ),
            checkBingJsonFields(
              jobStore.inputCache.get(`exp_configs[${index}].sydney.options`),
              convertPathToKey(`exp_configs[${index}].sydney.options`),
            ),
            checkNotEmpty(
              `exp_configs[${index}].sydney.options`,
              convertPathToKey(`exp_configs[${index}].sydney.options`),
            ),
            checkBingExpOptionSet(
              getValue(`exp_configs[${index}].sydney.option_sets`),
              convertPathToKey(`exp_configs[${index}].sydney.option_sets`),
            ),
            checkUrl(
              getValue(`exp_configs[${index}].sydney.url`),
              convertPathToKey(`exp_configs[${index}].sydney.url`),
            ),
            checkExpNameNotEmpty(
              getValue(`exp_configs[${index}].exp_name`),
              convertPathToKey(`exp_configs[${index}].exp_name`),
            ),
            checkBingCluster(getValue(`cluster`), convertPathToKey(`cluster`)),
            checkBingScenario(
              getValue(`scenario`),
              convertPathToKey(`scenario`),
            ),
          ])
          .concat(
            Object.entries(getValue("multi_turn") ?? []).map((item) => [
              checkBingTestSetOptions(
                item[1],
                convertPathToKey(`multi_turn.${item[0]}`),
              ),
            ]),
          )
          .concat(
            Object.entries(getValue("single_turn") ?? []).map((item) => [
              checkBingTestSetOptions(
                item[1],
                convertPathToKey(`single_turn.${item[0]}`),
              ),
            ]),
          )
          .concat(
            (["user_id"] as const).map((path) =>
              checkBingUserId(getValue(path), convertPathToKey(path)),
            ),
          )
          .reduce((acc, val) => acc.concat(val), []),
      );

    case TemplateType.OPG:
    case TemplateType.GeneralSkipScraping:
      return compact(getInputFolderErrors());

    default:
      return [];
  }
});
