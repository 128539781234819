import { mutatorAction } from "satcheljs";

import type { Product } from "sydneyeval-shared";
import type { Toaster } from "../../../components/Wrappers/ToasterProvider";
import type { JobTemplate } from "../models/JobTemplate";
import {
  productSettingsStore,
  type ProductSettingsScenario,
} from "../store/productSettingsStore";

export const updateProductSettingScenario = mutatorAction(
  "updateProductSettingScenario",
  (scenario: ProductSettingsScenario) => {
    productSettingsStore.scenario = scenario;
  },
);

export const updateProducts = mutatorAction(
  "updateProducts",
  (products: Product[]) => {
    productSettingsStore.products = products;
  },
);

export const updateJobTemplates = mutatorAction(
  "updateJobTemplates",
  (jobTemplates: JobTemplate[]) => {
    productSettingsStore.templates = jobTemplates;
  },
);

export const updateToaster = mutatorAction(
  "updateToaster",
  (toaster: Toaster | undefined) => {
    productSettingsStore.toaster = toaster;
  },
);
