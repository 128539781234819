import { Dropdown, Option } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import type { LMChecklistAssertionViewType } from "../../../helpers/lmchecklistMetricsHelper";
import { getPassFailRatesByQuery } from "../../../helpers/lmchecklistMetricsHelper";
import type { Job } from "../../../models/Job";
import type { LMChecklistAssertionsResponse } from "../../../models/LMChecklistAssertionsResponse";
import type { LMChecklistDetailsResponse } from "../../../models/LMChecklistDetailsResponse";
import type { PassFailRateByQuery } from "../../../models/LMChecklistPassFailRates";
import { LMChecklistAssertionsTable } from "../Table/LMChecklistAssertionsTable";
import { LMChecklistQueryTable } from "../Table/LMChecklistQueryTable";
import { useResultViewStyles } from "../sharedStyles";

type View = "Query" | "Assertion";

type MetricsDiagnosisTableProps = {
  job: Job;
  jobId: string;
  jobName: string;
  lmchecklistDetailsResponse?: LMChecklistDetailsResponse;
  lmchecklistAssertionsResponse?: LMChecklistAssertionsResponse;
  assertionViewType: LMChecklistAssertionViewType;
};

export const LMChecklistView = observer((props: MetricsDiagnosisTableProps) => {
  // TODO: Support Segmnent
  const styles = useResultViewStyles();

  const [selectedView, setSelectedView] = React.useState<View>(
    props.assertionViewType === "Regressions" ? "Assertion" : "Query",
  );
  const [pfRates, setPFRates] = React.useState<PassFailRateByQuery[]>([]);

  React.useEffect(() => {
    if (
      props.lmchecklistDetailsResponse &&
      props.lmchecklistAssertionsResponse
    ) {
      const assertions = props.lmchecklistAssertionsResponse;
      const pfMap = props.lmchecklistDetailsResponse.map((record) =>
        getPassFailRatesByQuery(assertions, record),
      );
      setPFRates(pfMap);
    }
  }, [props.lmchecklistDetailsResponse, props.lmchecklistAssertionsResponse]);

  const handleViewSelect = (view: View) => {
    setSelectedView(view);
  };

  return (
    <div className={styles.root}>
      <div className={styles.actionBar}>
        <Dropdown
          className={styles.dropdown}
          value={`${selectedView} View`}
          selectedOptions={[selectedView]}
          onOptionSelect={(_, option) =>
            handleViewSelect(option.optionValue as View)
          }
        >
          <Option value={"Query"}>Query View</Option>
          <Option value={"Assertion"}>Assertion View</Option>
        </Dropdown>
      </div>
      {selectedView === "Query" &&
        props.lmchecklistDetailsResponse &&
        props.lmchecklistAssertionsResponse && (
          <LMChecklistQueryTable
            job={props.job}
            jobId={props.jobId}
            details={props.lmchecklistDetailsResponse}
            assertions={props.lmchecklistAssertionsResponse}
            pfRates={pfRates}
          />
        )}
      {selectedView === "Assertion" && props.lmchecklistAssertionsResponse && (
        <LMChecklistAssertionsTable
          jobName={props.jobName}
          assertions={props.lmchecklistAssertionsResponse}
          assertionViewType={props.assertionViewType}
        />
      )}
    </div>
  );
});
