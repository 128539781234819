import { useComboboxFilter } from "@fluentui/react-components";
import _, { cloneDeep, omit } from "lodash";
import { isValidSettingsThrowError } from "sydneyeval-shared";
import * as yaml from "yaml";
import { getRandomUUID } from "../../../helpers/getRandomUUID";
import { Term } from "../components/QueryManagement/types/Enum";
import type {
  Assertion,
  AssertionsYaml,
  QueriesTSV,
  Query,
  Snapshot,
  SnapshotQuery,
  TaggedAssertions,
} from "../components/QueryManagement/types/Query";
import * as qm from "./queryManagementHelper";
import schema from "./schema/lmchecklist_data_schema.json";

// ==============================
// FILE IMPORT HELPERS
// ==============================

export const isQuery = (object: Query | TaggedAssertions): object is Query => {
  return "query" in object;
};

export const isTaggedAssertions = (
  object: Query | TaggedAssertions,
): object is TaggedAssertions => {
  return "tag" in object;
};

export const splitQueryAndTagObjects = (
  yamlData: (Query | TaggedAssertions)[],
): Snapshot => {
  const queries = yamlData.filter(isQuery);
  const taggedAssertions = yamlData.filter(isTaggedAssertions);

  return { queries, taggedAssertions };
};

export const gatherTaggedAssertions = (
  tags: string[] | undefined,
  taggedAssertions: TaggedAssertions[],
) => {
  if (tags === undefined) {
    return [];
  }

  let assertions: Assertion[] = [];
  tags.forEach((tag) => {
    const tagged = taggedAssertions.find((tagItem) => tagItem.tag === tag);
    if (tagged) {
      if (tagged.assertions) {
        tagged.assertions.forEach((assertion) => {
          assertion.fromTag = tag;
        });
        assertions = assertions.concat(tagged.assertions);
      }

      assertions = assertions.concat(
        gatherTaggedAssertions(tagged.tags, taggedAssertions),
      );
    }
  });

  return assertions;
};

export const processImport = (data: Snapshot) => {
  const { queries, taggedAssertions } = data;

  taggedAssertions.forEach((tagged) => {
    tagged.assertions &&
      tagged.assertions.forEach((assertion) => {
        assertion.id = getRandomUUID();
        return assertion;
      });
  });

  queries.forEach((query) => {
    query.assertions &&
      query.assertions.forEach((assertion) => {
        assertion.id = getRandomUUID();
      });

    const gatheredTaggedAssertions = gatherTaggedAssertions(
      query.tags,
      taggedAssertions,
    );

    if (gatheredTaggedAssertions.length > 0) {
      if (query.assertions === undefined) {
        query.assertions = [];
      }
      query.assertions = query.assertions.concat(gatheredTaggedAssertions);
    }
  });
};

export const importSnapshot = (file: File): Promise<Snapshot> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const yamlData = yaml.parse(reader.result as string);
      const splitData = splitQueryAndTagObjects(yamlData);
      processImport(splitData);
      resolve(splitData);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsText(file);
  });
};

export const validateImportFileSchema = async (file: File) => {
  const schemaObj = {
    ...schema,
    definitions: {
      ...schema.definitions,
      QueryElement: {
        ...schema.definitions.QueryElement,
        required: ["id", "query", "segmentTwo"],
      },
    },
  };

  try {
    const fileText = await file.text();
    const parsedFile = yaml.parse(fileText);

    isValidSettingsThrowError(
      JSON.stringify(parsedFile),
      JSON.stringify(schemaObj),
    );

    return file;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(`YAML parsing error: ${e.message}`);
    } else {
      throw new Error("YAML parsing error");
    }
  }
};

// ==============================
// FILE EXPORT HELPERS
// ==============================

export const downloadData = (blob: Blob, fileName: string) => {
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const convertToQueriesTSVSchema = (queries: Query[]): QueriesTSV[] => {
  const queriesTSV: QueriesTSV[] = queries.map((query) => {
    const term = query.term ?? "";
    const type =
      query.sets?.find((set) => set.toLowerCase().includes("hop")) || "";
    const ciq = query.sets?.includes("CIQ") ? "CIQ" : "";

    const sets =
      query.sets
        ?.filter((set) => !set.toLowerCase().includes("hop") && set !== "CIQ")
        .join(",") || "";

    const annotations: string =
      query.annotations
        ?.map(
          (annotation) =>
            `[{"text":"${annotation.text}","id":"${annotation.id}","QueryAnnotationType":"${annotation.queryAnnotationType}"}]`,
        )
        .join(", ") || "";

    return {
      Utterance: query.query,
      "Segment 1": query.segmentOne ?? "",
      "Segment 2": query.segmentTwo,
      "Query Owner": query.queryOwner ?? "",
      Environment: query.environment ?? "",
      Term: term === Term.None ? "" : term,
      "FRE or Sparkle Prompt": query.freOrSparklePrompt ?? "",
      CIQ: ciq,
      Type: type,
      "Grounding Data Source": query.groundingDataSource ?? "",
      "Data source information": query.dataSourceInfo ?? "",
      Sets: sets,
      annotation: annotations,
      label: query.label ?? "",
      plugins: query.plugins ?? "",
      pluginoptions: query.pluginoptions ?? "",
    };
  });

  return queriesTSV;
};

export const getDefaultTSVName = (includeDrafts: boolean) => {
  return includeDrafts ? "QuerySet_with_drafts" : "QuerySet";
};

export const exportQueriesToTSV = (
  queries: Query[],
  fileName: string,
  includeDrafts: boolean,
) => {
  const filteredQueries = queries.filter(
    (query) => (includeDrafts || !query.draft) && !query.archived,
  );

  const queriesTSV = qm.convertToQueriesTSVSchema(filteredQueries);
  const allMSIT = queriesTSV.every((query) => query.Environment === "MSIT");
  if (allMSIT) {
    queriesTSV.forEach((query) => delete query.annotation);
  }

  const colNames = Object.keys(queriesTSV[0]).join("\t");
  const rows = queriesTSV
    .map((query) =>
      Object.values(query)
        .map((value) => value.replace(/[\n\r\t]/g, " "))
        .join("\t"),
    )
    .join("\n");

  const tsv = `${colNames}\n${rows}`;
  const blob = new Blob([tsv], { type: "text/tsv" });
  fileName = fileName || qm.getDefaultTSVName(includeDrafts);
  qm.downloadData(blob, `${fileName}.tsv`);
};

export const removeEmptyProps = (
  obj: Query | Assertion | AssertionsYaml | TaggedAssertions,
) => {
  const keys = Object.keys(obj) as (keyof typeof obj)[];
  keys.forEach((key) => {
    const value = obj[key];
    if (
      value === undefined ||
      value === "" ||
      (Array.isArray(value) && (value as unknown[]).length === 0)
    ) {
      delete obj[key];
    }
  });
};

export const exportTaggedSet = (
  queries: Query[],
  taggedAssertions: TaggedAssertions[],
  set: string,
  includeQueryDrafts: boolean,
  includeAssertionDrafts: boolean,
) => {
  const init: {
    MSIT: Query[];
    TestTenant: Query[];
  } = { MSIT: [], TestTenant: [] };

  const querySets = queries.reduce((acc, query) => {
    if (query.sets?.includes(set)) {
      if (query.environment === "MSIT") {
        acc.MSIT.push(query);
      } else if (query.environment === "Test Tenant") {
        acc.TestTenant.push(query);
      }
    }
    return acc;
  }, init);

  Object.entries(querySets).forEach(([key, querySet]) => {
    if (querySet.length > 0) {
      const setName = `${set}_Set_${key}`;
      qm.exportQueriesToTSV(querySet, setName, includeQueryDrafts);
      qm.exportSnapshot(
        `${setName}_Snapshot`,
        querySet,
        taggedAssertions,
        includeQueryDrafts,
        includeAssertionDrafts,
      );
    }
  });
};

export const getEditedSets = (queries: Query[]) => {
  const editedSets = new Set<string>();
  queries.forEach((query) => {
    if (query.archived || query.edited) {
      query.sets?.forEach((set) => editedSets.add(set));
    }
  });

  return editedSets;
};

export const getDefaultSnapshotName = (
  includeQueryDrafts: boolean,
  includeAssertionDrafts: boolean,
) => {
  let fileName = "Snapshot";
  if (includeQueryDrafts && includeAssertionDrafts) {
    fileName += "_with_query_and_assertion_drafts";
  } else if (includeQueryDrafts) {
    fileName += "_with_query_drafts";
  } else if (includeAssertionDrafts) {
    fileName += "_with_assertion_drafts";
  }

  return fileName;
};

export const getSnapshotQueries = (
  queries: Query[],
  includeQueryDrafts: boolean,
  includeAssertionDrafts: boolean,
): SnapshotQuery[] =>
  queries
    .filter(
      ({ archived, draft }) => !archived && (includeQueryDrafts || !draft),
    )
    .map((query) => {
      const rest = omit(cloneDeep(query), ["draft", "archived", "edited"]);
      rest.assertions = rest.assertions
        ?.filter(
          ({ draft, fromTag }) =>
            !fromTag && (includeAssertionDrafts || !draft),
        )
        .map((assertion) => {
          const assertionRest = omit(cloneDeep(assertion), [
            "id",
            "draft",
            "fromTag",
          ]);
          removeEmptyProps(assertionRest);
          return assertionRest;
        });
      rest.term = rest.term === Term.None ? "" : rest.term;
      removeEmptyProps(rest);
      return rest;
    })
    .sort(
      (a, b) =>
        a.segmentTwo.localeCompare(b.segmentTwo) ||
        a.query.localeCompare(b.query),
    );

export const getTaggedAssertions = (
  taggedAssertions: TaggedAssertions[],
  includeAssertionDrafts: boolean,
): TaggedAssertions[] =>
  taggedAssertions
    .map((tagged) => ({
      ...tagged,
      assertions: tagged.assertions
        ?.filter(({ draft }) => includeAssertionDrafts || !draft)
        .map((assertion) => {
          const rest = omit(cloneDeep(assertion), ["id", "draft", "fromTag"]);
          removeEmptyProps(rest);
          return rest;
        }),
    }))
    .sort((a, b) => a.tag.localeCompare(b.tag));

export const exportSnapshot = (
  fileName: string,
  queries: Query[],
  taggedAssertions: TaggedAssertions[],
  includeQueryDrafts: boolean,
  includeAssertionDrafts: boolean,
) => {
  const sortedSnapshotQueries: SnapshotQuery[] = qm.getSnapshotQueries(
    queries,
    includeQueryDrafts,
    includeAssertionDrafts,
  );

  const sortedTaggedAssertions = qm.getTaggedAssertions(
    taggedAssertions,
    includeAssertionDrafts,
  );

  const snapshot = [...sortedSnapshotQueries, ...sortedTaggedAssertions];

  const originalLineWidth = yaml.scalarOptions.str.fold.lineWidth;
  yaml.scalarOptions.str.fold.lineWidth = Infinity;
  const yamlData = yaml.stringify(snapshot, {
    indent: 2,
    indentSeq: false,
  });
  yaml.scalarOptions.str.fold.lineWidth = originalLineWidth;

  const blob = new Blob([yamlData], { type: "text/yaml" });
  fileName =
    fileName ||
    qm.getDefaultSnapshotName(includeQueryDrafts, includeAssertionDrafts);
  qm.downloadData(blob, `${fileName}.yaml`);
};

// ==============================
// UI HELPERS
// ==============================

export const getOptions = <T>(queries: Query[], field: keyof Query) => {
  const options = [
    ...new Set(
      queries.reduce((acc: T[], query) => {
        const value = query[field];
        if (value) {
          if (Array.isArray(value)) {
            acc = acc.concat(value as T[]);
          } else {
            acc.push(value as T);
          }
        }
        return acc;
      }, []),
    ),
  ];

  return options;
};

export const getMatchingOptions = (
  string: string,
  options: string[],
  message?: string,
) => {
  return useComboboxFilter(string, options, {
    noOptionsMessage: message,
  });
};

export const omitProps = (
  obj: Query | Assertion,
  props: string[],
  conditions: { [key: string]: boolean | undefined },
) => {
  const propsToOmit = [...props];
  Object.keys(conditions).forEach((key) => {
    if (conditions[key] === false) {
      propsToOmit.push(key);
    }
  });
  return omit(obj, propsToOmit);
};

export const removeNonExportedProps = (queries: Query[]) => {
  return queries
    .filter((query) => !query.archived)
    .map((query) => {
      if (query.assertions) {
        query.assertions = query.assertions.map((assertion) => {
          removeEmptyProps(assertion);
          return omitProps(assertion, ["id", "fromTag"], {
            draft: assertion.draft,
          });
        }) as Assertion[];
      }

      removeEmptyProps(query);
      return omitProps(query, ["edited"], {
        archived: query.archived,
        draft: query.draft,
      });
    });
};

export const queriesEdited = (queries: Query[], initialQueries: Query[]) => {
  const standardizedQueries = removeNonExportedProps(cloneDeep(queries));
  const standardizedInitialQueries = removeNonExportedProps(
    cloneDeep(initialQueries),
  );
  return !_.isEqual(standardizedQueries, standardizedInitialQueries);
};
