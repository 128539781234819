import { isValidSettings } from "sydneyeval-shared";
import yaml from "yaml";
import { getFileName } from "../../../helpers/accountHelper";
import { getCustomLMChecklistSets } from "../../../helpers/apiHelper";
import { store } from "../../../store/store";
import { checkFileEncoding } from "./querySetHelper";
import schema from "./schema/lmchecklist_data_schema.json";

const checkFileExistence = (file: File) => {
  const fileName = `lmchecklist/${getFileName(store.account, file)}` ?? "";

  return getCustomLMChecklistSets().then((customLMChecklistSets) => {
    if (customLMChecklistSets.includes(fileName)) {
      throw new Error(`The file ${fileName} already exists.`);
    }

    return Promise.resolve(file);
  });
};

const checkFileSchema = async (file: File) => {
  try {
    const fileText = await file.text();
    const valid = isValidSettings(
      JSON.stringify(yaml.parse(fileText)),
      JSON.stringify(schema),
    );

    if (!valid) {
      throw new Error(`LMChecklist file schema is invalid.`);
    }

    return file;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(
        `LMChecklist file is invalid. YAML parsing error: ${e.message}`,
      );
    } else {
      throw new Error(`LMChecklist file is invalid.`);
    }
  }
};

export const checkLMChecklistSet = (file: File) => {
  return Promise.resolve(file)
    .then(checkFileEncoding)
    .then(checkFileExistence)
    .then(checkFileSchema);
};
