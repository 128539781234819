import { orchestrator } from "satcheljs";
import {
  uploadBingSkipScrapingFolder,
  uploadCWCSkipScrapingFolder,
  uploadOPGFolder,
} from "../../../helpers/apiHelper";
import { uploadFolderAction } from "../actions/uploadFolderAction";

orchestrator(uploadFolderAction, (actionMessage) => {
  const { folderType, folderName, folder, oldFolderName, onSuccess, onError } =
    actionMessage;
  const name = folderName.trim();

  if (name === "") {
    return;
  }

  switch (folderType) {
    case "OPG":
      uploadOPGFolder({
        remoteFolderName: name,
        folder: folder,
        localFolderName: oldFolderName,
      })
        .then(onSuccess)
        .catch(onError);
      break;

    case "Bing":
      uploadBingSkipScrapingFolder({
        remoteFolderName: name,
        folder: folder,
        localFolderName: oldFolderName,
      })
        .then(onSuccess)
        .catch(onError);
      break;

    case "CWC":
      uploadCWCSkipScrapingFolder({
        remoteFolderName: name,
        folder: folder,
        localFolderName: oldFolderName,
      })
        .then(onSuccess)
        .catch(onError);
      break;
  }
});
