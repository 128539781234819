import { Body1, Button, Link, mergeClasses } from "@fluentui/react-components";
import {
  ErrorCircle20Filled,
  Search16Regular,
  ThumbDislike16Regular,
  ThumbLike16Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react";
import React from "react";
import { getTheLastQueryInMultiturnMode } from "sydneyeval-shared";
import { ExpandIcon } from "../../../../../../components/Shared/Icons";
import { Tip } from "../../../../../../components/Shared/Tip";
import { openSidePane } from "../../../../../../mutators/sidePaneMutators";
import { store } from "../../../../../../store/store";
import { mapMetricsKey } from "../../../../helpers/metricsHelper";
import {
  getMetricFeedbackLink,
  getReasoningMetricKey,
  getSBSDebugLink,
} from "../../../../helpers/renderMetricsHelper";
import { updateExpandIndex } from "../../../../mutators/jobDetailsMutators";
import { uttToDisplay } from "../../../../selectors/getGeneralMetrics";
import { getPrivacyFilterSet } from "../../../../selectors/getPrivacyFilterSummary";
import type { MChatUtteranceMetricsSingleData } from "../../../../store/jobDetailStore";
import { jobDetailStore } from "../../../../store/jobDetailStore";
import { resultStore } from "../../../../store/resultStore";
import type { IDevUIDialogKeyProps } from "../../../Dialog/DevUIDialog";
import { DevUIDialog } from "../../../Dialog/DevUIDialog";
import { PrivacyFilterJudgementView } from "../../SidePane/PrivacyFilterJudgementView";
import { useSharedTableStyles } from "../shardTableStyles";
import { MChatMetricsSubTable } from "./MChatMetricsSubTable";
import { MChatMetricsValueCell } from "./MChatMetricsValueCell";
import { tableCellSharedStyle } from "./tableCellSharedStyles";

export const MChatMetricsBody = observer(() => {
  const styles = tableCellSharedStyle();
  const sharedStyles = useSharedTableStyles();
  // Get the first element of uttToDisplay.get()
  const displayUtterence = uttToDisplay.get();
  const enableMultiTurn = getTheLastQueryInMultiturnMode(
    displayUtterence && displayUtterence.length > 0
      ? displayUtterence[0].query
      : "",
  )
    ? true
    : false;
  const getDisplayQuery = (query: string) =>
    enableMultiTurn ? getTheLastQueryInMultiturnMode(query) : query;
  const utteranceCell = mergeClasses(sharedStyles.cell, styles.utteranceCell);

  const valueCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.valueCell,
    sharedStyles.rightCell,
  );

  const metricsHeaderCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.centerCell,
  );
  const metricsSubHeaderCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.centerCell,
    styles.subTitleCell,
  );
  const utteranceHeaderCell = mergeClasses(
    sharedStyles.cell,
    styles.utteranceHeaderCell,
  );

  const subTableCell = mergeClasses(sharedStyles.cell, styles.subTableCell);

  const handleExpand = (index: number) => {
    if (jobDetailStore.expandedIndex === index) {
      updateExpandIndex(undefined);
      return;
    }
    updateExpandIndex(index);
  };

  const getExpandStyle = (index: number) =>
    jobDetailStore.expandedIndex === index
      ? mergeClasses(sharedStyles.expand, sharedStyles.expanded)
      : sharedStyles.expand;

  const getClassName = (
    record: MChatUtteranceMetricsSingleData | undefined,
  ) => {
    if (record === undefined) {
      return valueCell;
    }

    const isPositive = Number(record.treatment) > Number(record.control);
    const isNegtive = Number(record.treatment) < Number(record.control);

    const className = mergeClasses(
      valueCell,
      isPositive ? styles.positiveCell : undefined,
      isNegtive ? styles.negativeCell : undefined,
    );

    return className;
  };

  const hasAccess =
    resultStore.resultJob?.Properties?.IsOwner === true ||
    resultStore.resultJob?.Properties?.IsTestTenant === true ||
    resultStore.resultJob?.CreatorSmtpAddress?.includes(
      store.account?.username ?? "",
    );

  const [isDevUIDialogOpen, setIsDevUIDialogOpen] =
    React.useState<boolean>(false);
  const [devUIDialogProps, setDevUIDialogProps] = React.useState<
    IDevUIDialogKeyProps | undefined
  >(undefined);
  return (
    <div>
      <table className={sharedStyles.table}>
        <thead className={sharedStyles.thead}>
          <tr className={sharedStyles.rowStyle}>
            <th className={utteranceHeaderCell} rowSpan={2}>
              Utterance
            </th>
            {jobDetailStore.selectedMChatMetrics.map((m) => {
              return (
                <th key={m} className={utteranceHeaderCell} colSpan={2}>
                  {mapMetricsKey(m)}
                </th>
              );
            })}
            <th className={metricsHeaderCell} colSpan={2}>
              DevUI links
            </th>
          </tr>
          <tr className={sharedStyles.rowStyle}>
            {jobDetailStore.selectedMChatMetrics.map((m) => {
              return (
                <React.Fragment key={m}>
                  <th className={metricsSubHeaderCell}>Control</th>
                  <th className={metricsSubHeaderCell}>Treatment</th>
                </React.Fragment>
              );
            })}
            <th className={metricsSubHeaderCell}>Control</th>
            <th className={metricsSubHeaderCell}>Treatment</th>
          </tr>
        </thead>
        <tbody className={sharedStyles.tbody}>
          {displayUtterence.map((utterance, index) => {
            const metrics = utterance.metrics;

            const sbs_debuglink = getSBSDebugLink(utterance.query);

            return (
              <React.Fragment key={utterance.query}>
                <tr className={sharedStyles.rowStyle}>
                  <td className={utteranceCell}>
                    <div
                      className={mergeClasses(
                        sharedStyles.longTextWrapper,
                        styles.utterance,
                      )}
                    >
                      <div
                        className={getExpandStyle(index)}
                        onClick={(event) => {
                          handleExpand(index);
                          event?.stopPropagation();
                        }}
                      >
                        <ExpandIcon />
                      </div>
                      <div className={sharedStyles.longText}>
                        <Tip
                          withArrow
                          content={utterance.query}
                          relationship="label"
                        >
                          {sbs_debuglink ? (
                            <Link target="_blank" href={sbs_debuglink}>
                              {getDisplayQuery(utterance.query)}
                              <Search16Regular className={styles.searchIcon} />
                            </Link>
                          ) : (
                            <Body1>{getDisplayQuery(utterance.query)}</Body1>
                          )}
                        </Tip>
                        {getPrivacyFilterSet
                          .get()
                          .includes(utterance.query) && (
                          <Tip
                            withArrow
                            content="Includes content blocked by Privacy Filter"
                            relationship="label"
                          >
                            <div
                              className={styles.privacyFilterFeedbackButton}
                              onClick={() => {
                                openSidePane(() => {
                                  return (
                                    <PrivacyFilterJudgementView
                                      query={utterance.query}
                                      jobId={resultStore.resultJob?.JobId ?? ""}
                                    />
                                  );
                                });
                              }}
                            >
                              <ErrorCircle20Filled />
                            </div>
                          </Tip>
                        )}
                      </div>
                      <div>
                        <Tip
                          withArrow
                          content="Metric SAT"
                          relationship="label"
                        >
                          <Button
                            aria-label="Metric SAT"
                            className={styles.metricFeedbackButton}
                            appearance="transparent"
                            icon={<ThumbLike16Regular />}
                            onClick={() =>
                              window.open(
                                getMetricFeedbackLink(
                                  "4df7d4e7-49a5-470f-8e7c-46859ddb76d6",
                                ),
                                "_blank",
                              )
                            }
                          />
                        </Tip>
                        <Tip
                          withArrow
                          content="Metric DSAT"
                          relationship="label"
                        >
                          <Button
                            aria-label="Metric DSAT"
                            className={styles.metricFeedbackButton}
                            appearance="transparent"
                            icon={<ThumbDislike16Regular />}
                            onClick={() =>
                              window.open(
                                getMetricFeedbackLink(
                                  "6c468d80-a0d4-4072-8322-b424bb597033",
                                ),
                                "_blank",
                              )
                            }
                          />
                        </Tip>
                      </div>
                    </div>
                  </td>
                  {jobDetailStore.selectedMChatMetrics.map((key) => {
                    const targetRecord = metrics.find((_) => _.key === key);
                    const className = getClassName(targetRecord);

                    const reasoningMetricKey = getReasoningMetricKey(
                      mapMetricsKey(key),
                    );

                    return (
                      <React.Fragment key={key}>
                        <td className={valueCell}>
                          <MChatMetricsValueCell
                            metricKey={targetRecord?.key ?? ""}
                            expName="control"
                            content={targetRecord?.control.toFixed(2) ?? ""}
                            reasoningMetricKey={reasoningMetricKey}
                            query={utterance.query}
                          />
                        </td>
                        <td className={className}>
                          <MChatMetricsValueCell
                            metricKey={targetRecord?.key ?? ""}
                            expName="treatment"
                            content={targetRecord?.treatment.toFixed(2) ?? ""}
                            reasoningMetricKey={reasoningMetricKey}
                            query={utterance.query}
                          />
                        </td>
                      </React.Fragment>
                    );
                  })}
                  <td className={valueCell}>
                    {hasAccess ? (
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          setIsDevUIDialogOpen(true);
                          setDevUIDialogProps({
                            query: utterance.query,
                            jobId: resultStore.resultJob?.JobId ?? "",
                            exp_name: "control",
                          });
                          event.stopPropagation();
                        }}
                      >
                        Control link
                      </a>
                    ) : (
                      "No Access"
                    )}
                  </td>
                  <td className={valueCell}>
                    {hasAccess ? (
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          setIsDevUIDialogOpen(true);
                          setDevUIDialogProps({
                            query: utterance.query,
                            jobId: resultStore.resultJob?.JobId ?? "",
                            exp_name: "experiment",
                          });
                          event.stopPropagation();
                        }}
                      >
                        Treatment link
                      </a>
                    ) : (
                      "No Access"
                    )}
                  </td>
                </tr>

                {index === jobDetailStore.expandedIndex && (
                  <tr className={sharedStyles.rowStyle}>
                    <td className={subTableCell} colSpan={11}>
                      <MChatMetricsSubTable utterance={utterance} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <DevUIDialog
        key={JSON.stringify(devUIDialogProps)}
        content={devUIDialogProps}
        isOpen={isDevUIDialogOpen}
        onComplete={() => {
          setIsDevUIDialogOpen(false);
        }}
      />
    </div>
  );
});
