import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import ReactMarkdown from "react-markdown";
import type { Incident } from "../../models/Incident";

type IncidentInfoDialogProps = {
  isOpen: boolean;
  incident: Incident;
  onComplete: () => void;
};

export const IncidentInfoDialog = (props: IncidentInfoDialogProps) => {
  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{`Incident: ${props.incident.title}`}</DialogTitle>
          <DialogContent>
            <ReactMarkdown>{props.incident.description}</ReactMarkdown>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
